import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm from 'react-hook-form';
import { Input } from '../../../Form';
import Clipboard from '../../../Clipboard';
import LoadButton from '../../../Button/LoadButton';
import history from '../../../../router/history';
import { archivePromo, editRefAction } from '../../../../actions';
import { setHtmlLinkHelper } from '../../utils';
import basket from '../../../../assets/img/basket.svg';
import './ReferralLink.scss';

function ReferralLink(props) {
    const {
        type,
        link: {
            link,
            name,
            linkid
        },
        loading,
        isWrapped: {
            link: banner
        },
        changeName,
        removePromo,
    } = props;

    const [ linkName, setLinkName ] = useState(undefined);
    const [ linkIndex, setLinkIndex ] = useState(undefined);
    const [ htmlLink, setHtmlLink ] = useState(undefined);

    const { register, handleSubmit } = useForm();

    useEffect( () => {
        setFields();
        // eslint-disable-next-line
    },[link, linkid]);

    const onSubmit = ({ name }) => {
        changeName(name);
    };

    const setFields = () => {
        setHtmlLink(setHtmlLinkHelper(type, link, banner));
        setLinkName(name);
        setLinkIndex(linkid);
    };

    return (
        <form
            className="referral__form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="referral__group">
                <div className="referral__name text">
                    UID:
                </div>
                <Input
                    className="referral__field"
                    inputClassName="referral__input"
                    name="referral_id"
                    refer={register}
                    defaultValue={linkIndex}
                    readOnly
                />
                <div className="referral__del"
                    onClick={() => {
                        removePromo();
                        history.push("/promos-archive");
                    }}
                >
                    <img src={basket} alt="del" />
                </div>
                <Clipboard
                    text={linkIndex}
                    className="referral__copy"
                />

            </div>
            <div className="referral__group">
                <div className="referral__name text">
                    Link:
                </div>
                <Input
                    className="referral__field"
                    inputClassName="referral__input"
                    name="referral_link"
                    refer={register}
                    defaultValue={htmlLink}
                    readOnly
                />
                <Clipboard
                    className="referral__copy"
                    text={htmlLink}
                />
            </div>
            <div className="referral__group">
                <div className="referral__name text">
                    Name:
                </div>
                <Input
                    className="referral__field"
                    inputClassName="referral__input"
                    name="name"
                    refer={register}
                    defaultValue={linkName}
                />
                <LoadButton
                    className="referral__save"
                    isLoad={loading}
                >
                    save
                </LoadButton>
            </div>
        </form>
    );
}

ReferralLink.propTypes = {
    hash: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    link: PropTypes.shape({
        id: PropTypes.number,
        link: PropTypes.string,
        linkid: PropTypes.string,
        name: PropTypes.string
    }).isRequired,
    loading: PropTypes.bool,
    isWrapped: PropTypes.shape({
        link: PropTypes.string,
        preview: PropTypes.string,
    }).isRequired,
    changeName: PropTypes.func.isRequired,
    removePromo: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { hash, link: { id } }) => {
    return {
        removePromo: () => {
            return dispatch(archivePromo({
                hash,
                id,
                action: 'delete',
            }))
        },
        changeName: (name) => {
            return dispatch(editRefAction({
                hash,
                id,
                name,
                action: 'changename'
            }))
        }
    }
};

export default connect(
    null,
    mapDispatchToProps
)(ReferralLink);