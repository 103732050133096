import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as UnCheckedIco} from '../../../assets/img/check.svg'
import {ReactComponent as CheckedIco} from '../../../assets/img/checked.svg'
import {ReactComponent as SmallUnCheckedIco} from '../../../assets/img/st_ch.svg';
import {ReactComponent as SmallCheckedIco} from '../../../assets/img/st_chcd.svg';
import './FakeCheckbox.scss';

const FakeCheckbox = ({ children, isActive, small }) => (
    <>
        {children}
        <div className="checkbox__check">
            {
                small ?
                    isActive ? <SmallCheckedIco /> : <SmallUnCheckedIco />  :
                    isActive ? <CheckedIco /> : <UnCheckedIco />
            }
        </div>
    </>
);

FakeCheckbox.defaultProps = {
    small: false,
};

FakeCheckbox.propTypes = {
    children: PropTypes.node.isRequired,
    isActive: PropTypes.bool.isRequired,
    small: PropTypes.bool,
};

export default FakeCheckbox;
