import { news } from '../testRestAPI';
import { PIECE_OF_NEWS } from '../constants/actionTypes';


const initialState = {
    newsList: news,
    news_item: []
};

const newsReducer = ( state = initialState, { type, payload } ) => {
    switch (type) {
        case PIECE_OF_NEWS:
            return {
                ...state,
                news_item: news.filter( news => news.id === payload)
            };
        default:
            return state;
    }
};

export default newsReducer;