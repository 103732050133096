import React, { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import AuthTitle from '../AuthTitle';
import SignInForm from './SignInForm';
import AuthFooter from '../AuthFooter';
import history from '../../../router/history';
import { AuthModalContext } from '../../../context';
import './SignIn.scss';

function SignIn({ logInfo: { data, error } }) {
    const {
        isOpenLogIn: isOpen,
        handleLogInModal: setIsOpen,
        handleSignUpModal: openRegModal,
    } = useContext(AuthModalContext);

    const loggedSuccess = useCallback(() => {
        if (data && data.login && isOpen) {
            setIsOpen(false);

            history.push('/dashboard');
        }
    }, [data, isOpen, setIsOpen]);

    useEffect(() => {
        loggedSuccess();
    }, [loggedSuccess]);

    useEffect(() => {
        const url  = new URL(window.location.href),
            isConfirmed = url.searchParams.get('login');

        if ( isConfirmed && !isOpen) {
            setIsOpen(true)
        }
    }, [isOpen, setIsOpen]);

    const handleOpenSignUp = () => {
        setIsOpen(false);
        openRegModal();
    };

    return (
        <Modal
            show={isOpen}
            className="sign-in"
            size="sm"
            onHide={() => {}}
        >
            <AuthTitle
                text="Log in"
                onClick={() => setIsOpen(!isOpen)}
            />
            <SignInForm
                isError={error}
            />
            <AuthFooter
                text="NOT A MEMBER?"
                closeModal={() => setIsOpen(!isOpen)}
                switchLogRegModal={handleOpenSignUp}
            />
        </Modal>
        )
}

SignIn.propTypes = {
    logInfo: PropTypes.shape({
        data: PropTypes.objectOf(PropTypes.string),
        loading: PropTypes.bool,
        error: PropTypes.string,
    }),
};

export default connect(({ logInfo }) => ({ logInfo }))(SignIn);