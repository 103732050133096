import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FieldLabel = ({ name, label, className }) => (
    <label
        className={classNames('mb-0 field__label text', className)}
        htmlFor={name}
    >
        { label }
    </label>
);

FieldLabel.defaultProps = {
    className: null
};

FieldLabel.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    name: PropTypes.string.isRequired
};

export default FieldLabel;