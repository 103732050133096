import React from 'react';
import PropTypes from 'prop-types';
import './AuthError.scss';

const AuthError = ({error}) => {

    const isError = () => {
        switch (error.type) {
            case 'required':
                return 'Required field';
            case 'minLength':
                return 'Must be at least 6 characters long';
            case 'pattern':
                return 'Not valid';
            case 'validate':
                return 'Passwords not match';
            default:
                return null
        }
    };

    return (
        <div className="auth-error">
            {error ? isError() : null }
        </div>
    );
};

AuthError.defaultProps = {
  error: null
};

AuthError.propTypes = {
    error: PropTypes.object
};

export default AuthError;
