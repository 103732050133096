import {
    LAYOUTS_REQUEST,
    LAYOUTS_SUCCESS,
    LAYOUTS_FAILURE,
} from '../../constants/actionTypes';

const initialState = {
    data: [],
    error: null,
    loading: false,
};

export default ( state = initialState, { type, payload } ) => {
    switch (type) {
        case LAYOUTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case LAYOUTS_SUCCESS:
            return {
                ...state,
                data: payload.data,
                loading: false
            };
        case LAYOUTS_FAILURE:
            return {
                ...state,
                error: 'some error',
                data: [],
                loading: false
            };
        default:
            return state;
    }
};