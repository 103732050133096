import axios from 'axios';
import { handler } from '../utils';
import { SIGN_OUT } from '../constants/actionTypes';

const URL = 'https://web.lucky4winners.com/api/?type=';
const HEADER = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
};

export function logIn(login, password) {
    return dispatch => {
        dispatch(handler.REQUEST('LOGIN'));
        axios({
            method: 'post',
            url: URL + 'auth',
            data: {
                login,
                password
            },
            headers: HEADER
        })
            .then( res => dispatch(handler.SUCCESS('LOGIN', res)) )
            .catch( err => dispatch(handler.FAILURE('LOGIN', err)) )
    }
}

export function logOut() {
    sessionStorage.clear();

    return {
        type: SIGN_OUT,
    };
}

export function changePassword(params){
    return dispatch => {
        axios({
            method: 'post',
            url: URL + 'changepassword',
            data: params,
            headers: HEADER
        })
            .then( res => dispatch(handler.SUCCESS('EDIT', res)) )
            .catch( err => dispatch(handler.FAILURE('EDIT', err)) )
    }
}

export function resetPassword(args) {
    return dispatch => {
        axios({
            method: 'post',
            url: URL + 'restorepassword',
            data: args,
            headers: HEADER
        })
            .then( res => dispatch(handler.SUCCESS('RESET', res)) )
            .catch( err => dispatch(handler.FAILURE('RESET', err)) )
    }
}

export function signUp(params) {
    return dispatch => {
        dispatch(handler.REQUEST('SIGN_UP'));
        axios({
            method: 'post',
            url: URL + 'reg',
            data: params,
            headers: HEADER
        })
            .then( res => dispatch(handler.SUCCESS('SIGN_UP', res)) )
            .catch( err => dispatch(handler.FAILURE('SIGN_UP', err)) )
    }
}
