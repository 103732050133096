import React, { useState } from 'react';
import PropTypes from "prop-types";
import LayoutBoxItem from '../LayoutBoxItem';
import ShortPreloader from '../../../ShortPreloader';
import { promoTypes } from '../../../../constants/promo';
import './LandingLayout.scss';

function LandingLayout({ data: { data, loading }, setPromo }) {
    const [ active, setActive ] = useState('');

    const handleClick = landing => () => {
        setActive(landing.name);
        setPromo(landing.name, 'layout');
        setPromo({
            name: landing.name,
            domain: landing.domain,
            type: promoTypes.landings,
            link:landing.preview
        }, 'selectedReferral');
    };

    if (loading) {
        return <ShortPreloader />
    }

    return (
        <div className="row layout">
            {data.map( (layout, id) =>
                <LayoutBoxItem
                    key={id}
                    list={layout}
                    active={active}
                    onClick={handleClick}
                />
            )}
        </div>
    )

}

LandingLayout.propTypes = {
    setPromo: PropTypes.func.isRequired,
    data: PropTypes.shape({
        loading: PropTypes.bool,
        data: PropTypes.array,
    }).isRequired

};

export default LandingLayout;
