import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from '../../router/history';
import './NewsCard.scss';

const NewsCard = ({item}) => {

    if ( item.length <= 0 ) {
        history.push('/news');

        return null;
    }

    return (
        <div className="news-item">
            <div className="pt-3 pb-3 pt-md-5 pb-md-5 container">
                <div className="pb-4 text-head">
                    {item[0].title}
                </div>
                <img className="pt-0" src={item[0].thumbnail} width="100%" alt="" />
                <div className="pt-4 pb-4 news-item__date text">
                    {item[0].date}
                </div>
                <div className="text pb-4">
                    {item[0].text}
                </div>
                <Link
                    className="more_btn news-item__btn"
                    to="/news"
                >
                    read all news
                </Link>
            </div>
        </div>
    )
};

NewsCard.propTypes = {
    item: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        text: PropTypes.string,
        thumbnail: PropTypes.string,
        date: PropTypes.string,
    })).isRequired,
};

export default connect(
    ({ news: { news_item: item } }) => ({ item })
)(NewsCard);
