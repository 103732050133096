import React from 'react';
import ContactForm from './ContactForm';
import ContactProps from './ContactProps';
import './Contacts.scss';


function Contacts() {

    return (
        <div className="contacts" id="contacts">
            <div className="pt-3 pb-3 pt-md-5 pb-md-5 container">
                <div className="container-title">
                    contact us
                </div>
                <div className="row">
                    <ContactProps
                        className="col-lg-6 order-lg-1"
                    />
                    <ContactForm
                        className="col-lg-6"
                    />
                </div>
            </div>
        </div>
    );
}

export default Contacts
