import {
    GET_PROMO_LOADING,
    GET_PROMO_SUCCESS,
    GET_PROMO_ERROR,
} from '../../constants/actionTypes';

const initialState = {
    data: [],
    loading: true,
    error: null,
    filterText: '',
};

export const promosList = ( state = initialState, { type, payload } ) => {
    switch (type) {
        case GET_PROMO_LOADING:
            return {
                ...state,
                loading: true,
                filterText: '',
            };
        case GET_PROMO_SUCCESS:
            return {
                ...state,
                data: payload.data.data,
                loading: false,
            };
        case "REMOVE_PROMO_LOCAL":
            return {
                ...state,
                data: state.data.filter( item => (
                    [payload].indexOf(item.id)
                )),
            };
        case "EDIT_PROMO_NAME":
            return {
                ...state,
                data: state.data.map( (item) => (
                    item.id === payload.id ?
                        {
                            ...item,
                            Name: payload.name
                        } : item
                ))
            };
        case "RELIVE_PROMO_STATIC":
            return {
                ...state,
                data: state.data.filter( (item) => (
                    [payload].indexOf(item.id)
                ))
            };
        case "FILTER_PROMO":
            return {
                ...state,
                filterText: payload,
            };
        case GET_PROMO_ERROR:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
};
