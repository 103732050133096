import React from 'react';
import PropTypes from 'prop-types';
import Board from '../../components/Board';
import Content from '../../components/Content';
import Clipboard from '../../components/Clipboard';
import './AffiliateLink.scss';

const AffiliateLink = ({ refid }) => {

    return (
        <div className="sub-aff">

            <Board
                className="pb-0"
                title="Sub-Affiliate link"
            >
                <b></b>
            </Board>
            <Content>
                <div className="mb-5 sub-affiliate__field">
                    <span className="text-small">Commission rate</span>
                    <span className="text">5%</span>
                </div>
                <div className="d-flex align-items-center sub-affiliate__field">
                    <span className="text-small">Referral link</span>
                    <input
                        name="sub-affiliate"
                        className="sub-affiliate__input"
                        value={`https://kingsclub.io/?refid=${refid}`}
                        readOnly
                    />
                    <Clipboard
                        className="ml-2 button-default"
                        text={`https://kingsclub.io/?refid=${refid}`}
                    />
                </div>
            </Content>
        </div>
    )
};

AffiliateLink.propTypes = {
    refid: PropTypes.string.isRequired
};

export default AffiliateLink;
