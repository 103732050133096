import {promoTypes} from '../../constants';

const isDisabled = ({ stepId, promo: { brand, type, layout, delivery } }) => {
    if ( stepId === 1 ) {
        return !!brand || false
    } else if ( stepId === 2 ) {
        return !!type || false
    } else if ( stepId === 3 ) {
        return !!layout || false
    } else if ( stepId === 4) {
        return !!delivery || false
    }

    return false;
};

const progressController = step => {

    if (step === 1) {
        return '0%'
    } else if (step === 2) {
        return '25%'
    } else if (step === 3) {
        return '50%'
    } else if (step === 4) {
        return '75%'
    } else {
        return '100%'
    }
};

const setHtmlLinkHelper = (type, link, banner) => {
    switch (type) {
        case promoTypes.video:
            return (
                `<a href="${link}">` +
                `<video width="300" autoPlay>` +
                `<source src="${banner}" type="video/mp4" />` +
                `</video>` +
                `</a>`
            );
        case promoTypes.banners:
        case promoTypes.brandings:
            return (
                `<a href="${link}">` +
                `<img width="300" src="${banner}" alt="" />` +
                `</a>`
            );
        default:
            return link;
    }
};

export {
    isDisabled,
    progressController,
    setHtmlLinkHelper
};