import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StatsContext } from '../../../context';

function StatsState({children, getStats, hash, promoId}) {
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [rangeHandler, setRangeHandler] = useState(false);
    const [Offers, setOffers] = useState(promoId ? [promoId.value] : []);
    const [Link_ID, setLink_ID] = useState([]);
    const [selected, setSelected] = useState(promoId ? [promoId] : []);
    const [page, setPage] = useState(1);
    const [option, setOption] = useState('date');
    const [tableOption, setTableOption] = useState('date');

    const params = [{hash, from, to, Offers, Link_ID, option, page, limit: 1111}];

    useEffect(() => {
        getStats(JSON.stringify(...params));
        // eslint-disable-next-line
    }, [page, rangeHandler]);

    const onSubmit = event => {
        event.preventDefault();

        if (page >= 2) {
            setPage(1)
        }

        setTableOption(option);

        getStats(JSON.stringify(...params));
    };

    return (
        <StatsContext.Provider
            value={{
                date: {
                    from,
                    to,
                    setFrom,
                    setTo,
                    rangeHandler,
                    setRangeHandler
                },
                media_name: {
                    setOffers,
                    setLink_ID,
                    selected,
                    setSelected
                },
                page,
                setPage,
                option,
                setOption,
                tableOption,
                onSubmit
            }}
        >
            {children}
        </StatsContext.Provider>
    )
}

StatsState.propTypes = {
    children: PropTypes.node,
    getStats: PropTypes.func.isRequired,
    hash: PropTypes.string.isRequired,
    promoId: PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string
    })
};

export default StatsState;