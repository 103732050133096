function saveStorage({logInfo}) {
    try {
        const serializedState = JSON.stringify({logInfo});

        sessionStorage.setItem('state', serializedState);
    } catch(e) {
        console.log(e)
    }
}

function persistedState() {
    try {
        const serializedState = sessionStorage.getItem('state');

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState)
    } catch(e) {
        return undefined
    }
}

export {
    saveStorage,
    persistedState,
}