import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShortPreloader from '../../../ShortPreloader';
import LayoutBoxItem from '../LayoutBoxItem';
import Button from '../../../Button';
import './VideoLayout.scss';

function VideoLayout({ layout, data: { data, loading }, setPromo }) {
    const [active, setActive] = useState(layout);
    const [visible, setVisible] = useState(20);

    const handleClick = v => () => {
        setActive(v.name);
        setPromo(v.name, 'layout');
        setPromo({ link: v.link }, 'isWrapped');
    };

    if (loading) {
        return <ShortPreloader />;
    }

    return (
        <div className="row layout">
            {data.slice(0, visible).map((video, id) => (
                <LayoutBoxItem
                    key={id}
                    list={video}
                    active={active}
                    onClick={handleClick}
                />
            ))}
            <Button
                className="mt-5 layout__more"
                onClick={() => setVisible(visible + 20)}>
                more
            </Button>
        </div>
    );
}


VideoLayout.propTypes = {
    data: PropTypes.shape({
        loading: PropTypes.bool,
        data: PropTypes.array,
    }).isRequired,
    layout: PropTypes.string.isRequired,
    setPromo: PropTypes.func.isRequired,
};

export default VideoLayout;
