import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect }  from 'react-redux';
import { Link } from "react-router-dom";
import Board from '../../components/Board';
import Content from '../../components/Content';
import MoneyBalance from "../../components/MoneyBalance";
import PromoFilter from '../../components/PromoList/PromoFilter';
import PromoList from '../../components/PromoList';
import { PromoModal, ModalState } from '../../components/PromoList/PromoModal';
import { PreviewModal, PreviewState } from '../../components/PromoList/PreviewModal';

import { getPromo, archivePromo } from "../../actions";
import './Promos.scss';

function Promos({ hash, loading, data, getPromo, archivePromo }) {

    useEffect(() => {
        getPromo();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="promos">
            <Board title="List of Promos">
                <div className="row">
                    <div className="col-12 col-xl-6">
                        <MoneyBalance
                            mode={1}
                            boardClassName="d-none d-xl-flex"
                        />
                    </div>
                    <PromoFilter
                        className="col-12 col-xl-6"
                    />
                </div>
            </Board>
            <Content>
                <ModalState>
                    <PreviewState>
                        <PromoList
                            hash={hash}
                            loading={loading}
                            data={data.reverse()}
                            archivePromo={archivePromo}
                            isPromos
                        />
                        <Link
                            className="mt-4 d-block button-default"
                            to="/promos-archive"
                        >
                            archive
                        </Link>
                        <PromoModal
                            hash={hash}
                        />
                        <PreviewModal />
                    </PreviewState>
                </ModalState>
            </Content>
        </div>
    )
}

Promos.propTypes = {
    hash: PropTypes.string,
    data: PropTypes.array,
    loading: PropTypes.bool,
    getPromo: PropTypes.func.isRequired,
    archivePromo: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { hash }) => {
    return {
        archivePromo: (id) => {
            dispatch(archivePromo({
                hash,
                id,
                action: 'delete',
            }))
        },
        getPromo: () => dispatch(getPromo({ hash })),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(Promos);