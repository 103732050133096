import React, { useContext } from 'react';
import { Select } from '../../../Form';
import CalendarButtons from '../../../Calendar/CalendarButtons';
import { StatsContext } from '../../../../context';
import { graphicOptions } from '../../utils';

function GraphicFilter (props) {
    const {
        data,
        fValue,
        sValue,
        setFvalue,
        setSvalue,
    } = props;

    const {
        date: {
            setFrom,
            setTo,
            rangeHandler,
            setRangeHandler
        },
        setPage,
    } = useContext(StatsContext);

    const options = Object.keys(data[0]).map( i =>
        graphicOptions.includes(i) ?
            ({ value: i, label: i.replace(/_/g, ' ') })
            : null
    );

    const handleSetFirstGraph = (e) => {
        setFvalue(Object.values(e)[0])
    };

    const handleSetSecondGraph = (e) => {
        setSvalue(Object.values(e)[0])
    };

    return (
        <div className="row mb-4">

            <div className="col-12 col-md-4">
                <Select
                    value={[{value: fValue, label: fValue}]}
                    name="graph-val"
                    setValue={handleSetFirstGraph}
                    options={options.filter( i => i !== null )}
                    onChange
                />
            </div>
            <div className="col-12 col-md-4">
                <Select
                    value={[{value: sValue, label: sValue}]}
                    name="graph-val-2"
                    setValue={handleSetSecondGraph}
                    options={options.filter( i => i !== null )}
                    onChange
                />
            </div>
            <div className="col-12 col-md-4">
                <CalendarButtons
                    sets={{
                        setFrom,
                        setTo,
                        rangeHandler,
                        setRangeHandler,
                        setPage,
                    }}
                />
            </div>
        </div>
    );
}

export default GraphicFilter;
