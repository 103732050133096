import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Content from '../../components/Content';
import MoneyBalance from "../../components/MoneyBalance";
import { ProfileTable, ProfileForm } from '../../components/ProfileInfo';
import Board from '../../components/Board';
import './Profile.scss';

function Profile (props) {
    const {
        hash, data, loading, changed, changeErr, payInfo,
        getPayments, changePassword, cparate, refshare
    } = props;

    useEffect( () => {
        getPayments({ hash });
        // eslint-disable-next-line
    }, []);

    return (
        <div className="profile">
            <Board title="profile" className="profile__board">
                <MoneyBalance
                    boardClassName="d-none d-xl-flex col-12 col-lg-6"
                    mode={1}
                />
            </Board>
            <Content>
                <div className="profile__wrapper">
                    <div className="mb-3 profile__title">
                        Change password
                    </div>
                    <div className="row">
                        <ProfileForm
                            hash={hash}
                            isChanged={changed}
                            changeErr={changeErr}
                            className="mb-4 col-12 col-md-6 col-xl-4"
                            changePassword={changePassword}
                        />
                        <ProfileTable
                            className="col-12 col-xl-8"
                            data={data}
                            payInfo={payInfo}
                            cparate={cparate}
                            refshare={refshare}
                            loading={loading}
                        />
                    </div>
                </div>
            </Content>
        </div>
    )
}

PropTypes.defaultProps = {
    error: null
};

PropTypes.propTypes = {
    hash: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    changed: PropTypes.bool.isRequired,
    changeErr: PropTypes.string.isRequired,
    payInfo: PropTypes.string.isRequired,
    error: PropTypes.string,
    getPayments: PropTypes.func.isRequired,
    cparate: PropTypes.string.isRequired,
    refshare: PropTypes.string.isRequired,
    changePassword: PropTypes.func.isRequired
};

export default Profile;