import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from '../../Calendar';
import { Select } from '../../Form';
import OptionFilter from './OptionFilter';
import { StatsContext } from '../../../context';
import { mediaOptions } from '../utils';
import './StatsFilter.scss';

function StatsFilter({ offers }) {
    const {
        option,
        setOption,
        media_name: {
            setOffers,
            setLink_ID,
            selected,
            setSelected,
        },
        date: {
            from,
            setFrom,
            to,
            setTo
        },
        onSubmit
    } = useContext(StatsContext);

    const handleSetMedias = offersArr => {
        const byId = [], byLinkId = [];

        if (offersArr) {
            offersArr.forEach( ({ value }) => {
                if ( isNaN(+value) ) byLinkId.push(value);
                else byId.push(value);
            });

            setOffers(byId);
            setLink_ID(byLinkId);
        } else {
            setOffers([]);
            setLink_ID([]);
        }

        setSelected(offersArr);
    };

    return (
        <form className="row" onSubmit={onSubmit}>
            <div className="col-12 col-md-6 col-lg-4">
                <Select
                    value={selected}
                    label="Media-name / UID"
                    labelClassName="stats__label"
                    name="Affiliate"
                    isMulti
                    isSearchable
                    options={mediaOptions(option, offers)}
                    setValue={handleSetMedias}
                    onChange
                />
                <Select
                    label="Brands"
                    labelClassName="stats__label"
                    name="Brands"
                    options={[{value: 'king-billy', label: 'King Billy'}]}
                    value={{value: 'king-billy', label: 'King Billy'}}
                    className="stats__input"
                />
            </div>
            <Calendar
                className="mt-4 mt-md-0 col-12 col-md-6 col-lg-4"
                sets={{
                    to, setTo,
                    from, setFrom
                }}
            />
            <OptionFilter
                className="col-12 col-md-12 col-lg-4"
                activeOption={option}
                setOption={setOption}
                setLink_ID={setLink_ID}
                selected={selected}
                setSelected={setSelected}
            />
        </form>
    );
}

StatsFilter.propTypes = {
    offers: PropTypes.arrayOf(PropTypes.object),
};

export default StatsFilter;