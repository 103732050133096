import React from 'react';
import './SignUpCongrats.scss';
import img from '../../../../assets/img/chips to STATS.png';

const SignUpCongrats = () => {
    return (
        <div className="reg-modal__congrats congrats">
            <img src={img} className="congrats__img" alt="chips" />
            <div className="text-head congrats__title">
                congratulations
            </div>
            <div className="text congrats__text">
                After registration, you will receive an
                email with a link to activate your account.
            </div>
            <div className="text-small congrats__check-mail">
                check your E:mail
            </div>
        </div>
    );
};

export default SignUpCongrats;
