import axios from "axios";
import { handler } from '../utils';

const URL = 'https://web.lucky4winners.com/api/?type=offer';

export function setPostBack(params) {
    return dispatch => {
        dispatch(handler.REQUEST('POST_BACK'));
        axios({
            method: 'post',
            url: URL,
            data: params,
            headers: {
                'Cache-Control': 'no-cache',
                'Content-type': 'application/json',
            }
        })
            .then(res => dispatch(handler.SUCCESS('POST_BACK', res)))
            .catch(err => dispatch(handler.FAILURE('POST_BACK', err)))
    }
};
