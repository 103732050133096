import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { Calendar } from '../../Calendar';
import Button from '../../Button';
import PaymentInfo from './PaymentInfo';
import CalendarButtons from '../../Calendar/CalendarButtons';
import coin from '../../../assets/img/chips to STATS.png';
import './PaymentForm.scss';

function PaymentForm({ hash, payInfo }) {
    const [ from, setFrom ] = useState(null);
    const [ to, setTo ] = useState(null);

    const { handleSubmit } = useForm();

    const onDateSubmit = () => { };

    return (
        <div className="row payment">
            <div className="d-none d-xl-block col-xl-4">
                <img src={coin} width="150" alt="coin"/>
            </div>
            <PaymentInfo
                className="col-12 col-md-6 col-xl-4 mb-4"
                hash={hash}
                payInfo={payInfo}
            />
            <div className="col-12 col-md-6 col-xl-4">
                <form
                    className="payment__form"
                    onSubmit={handleSubmit(onDateSubmit)}
                >
                    <CalendarButtons
                        className="payment__date-button"
                        sets={{
                            setFrom,
                            setTo
                        }}
                    />
                    <Calendar
                        className="payment__form_calendar"
                        sets={{
                            to, setTo,
                            from, setFrom
                        }}
                    />
                    <Button className="payment__form_submit">
                        APPLY
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default PaymentForm;