import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalContext } from './modalContext';

function ModalState({children}) {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ promoID, setPromoID ] = useState(null);
    const [ promoName, setPromoName ] = useState(null);
    const [ changereg, setChangereg ] = useState(null);
    const [ changedep, setChangedep ] = useState(null);
    const [ changeaccudep, setChangeaccudep ] = useState(null);

    const openModal = () => {
        if ( !isOpen ) {
            setIsOpen(true);
        }
    };

    const closeModal = () => {
        if ( isOpen ) {
            setIsOpen(false);
        }
    };

    return (
        <ModalContext.Provider value={{
            isOpen,
            promoID, setPromoID,
            promoName, setPromoName,
            changereg, setChangereg,
            changedep, setChangedep,
            changeaccudep, setChangeaccudep,
            openModal, closeModal
        }}>
            {children}
        </ModalContext.Provider>
    );
}

ModalState.propTypes = {
    children: PropTypes.node.isRequired
};

export default ModalState;