import React from 'react';
import PropTypes from 'prop-types';
import inneRrates from '../../../assets/img/inner/CS_imagechips.png';
import innerToday from '../../../assets/img/inner/CS_imagechips1.png';
import innerBalance from '../../../assets/img/inner/CS_imagechips2.png';
import './BoardBalance.scss';

const BoardBalance = ({
    moneyStatus: {
        cparate,
        refshare,
        today,
        balance
    },
    boardClassName
}) => (
    <div className={`inner-balance ${boardClassName}`}>
        <div className="col-4 text-center inner-balance__item">
            <img className="inner-balance__label" src={inneRrates} alt="rates"/>
            <div className="text-small inner-balance__data">
                rates: <span>{cparate}/{refshare}</span>
            </div>
        </div>
        <div className="col-4 text-center inner-balance__item">
            <img className="inner-balance__label" src={innerToday} alt="rates"/>
            <div className="text-small inner-balance__data">
                today: <span>{parseFloat(today).toFixed(2)}</span>
            </div>
        </div>
        <div className="col-4 text-center inner-balance__item">
            <img className="inner-balance__label" src={innerBalance} alt="rates"/>
            <div className="text-small inner-balance__data">
                balance: <span>{parseFloat(balance).toFixed(2)}</span>
            </div>
        </div>
    </div>
);

BoardBalance.defaultProps = {
    boardClassName: null,
};

BoardBalance.propTypes = {
    moneyStatus: PropTypes.objectOf(PropTypes.string).isRequired,
    boardClassName: PropTypes.string
};

export default BoardBalance;
