import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ClampLines from 'react-clamp-lines';
import { Link , withRouter } from 'react-router-dom';
import peso from '../../assets/img/two_chip.png';
import chips from '../../assets/img/happy_chips.png';
import './Testimonials.scss';

function Testimonials({ match, testimonials, getTestimonials })  {
    const isIndexPage = match.path.length === 1;

    useEffect(() => {
        getTestimonials();
        // eslint-disable-next-line
    },[]);

    if (isIndexPage) {
        testimonials = testimonials.slice(0, 4);
    }

    return (
        <div className="testimonials">
            {!isIndexPage ? <img src={chips} width="100%" alt="chips" />: null }
            <div className="pt-3 pb-3 pt-md-5 pb-md-5 container">
                <div className="container-title">
                    testimonials
                </div>
                <div className="pb-lg-6 row">
                    { testimonials.map( review => (
                        <div key={review.id} className="col-lg-6 mb-4">
                            <Link
                                to={{
                                    pathname: `/testimonials/${review.id}`,
                                    id: review.id
                                }}
                                  className="testimonials__item"
                            >
                                <div className="pl-2 text-head testimonials__title">{}
                                    {review.logo ? <img src={review.logo} alt='' />: review.title}
                                </div>
                                <div className="text testimonials__text">
                                    <ClampLines
                                        id={review.id}
                                        className="text"
                                        text={review.text}
                                        lines={3}
                                        ellipsis="..."
                                        buttons={false}
                                    />
                                </div>
                            </Link>
                            <div className="mt-2 text testimonials__brand ">
                                <a href={`https://${review.link || review.brand}`} target="blank">{review.brand}</a>
                            </div>
                        </div>
                    ))}
                </div>
                {isIndexPage ? <Link to="/testimonials" className="more_btn">read all testimonials</Link>: null }
                <img src={peso} className="d-none d-lg-block testimonials__img" alt="peso" />
            </div>
        </div>
    );
}

Testimonials.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired
    }).isRequired,
    testimonials: PropTypes.arrayOf(PropTypes.object),
    getTestimonials: PropTypes.func.isRequired,
};

const mapStateToProps = ({ testimonials: { testimonials } }) => ({testimonials});
const mapDispatchToProps = dispatch => ({
    getTestimonials: () => dispatch({ type: 'GET_ALL' })
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Testimonials);
