import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../Button';
import { Input } from '../../Form';
import ReferralLink from './ReferralLink';
import PostBack from './Postback';
import PromoLinkInfo from './PromoLinkInfo';
import './PromoLink.scss';

function PromoLink(props) {
    const {
        hash, link, lang,
        type, loading,
        isWrapped,
        getRefAction,
        resetPromo
    } = props;

    useEffect(() => {
        getRefAction()
        // eslint-disable-next-line
    }, []);

    return (
        <div className="referral">
            <Button
                className="referral__create-new"
                onClick={resetPromo}
            >
                Creating new promo
            </Button>
            <ReferralLink
                hash={hash}
                link={link}
                loading={loading}
                type={type}
                isWrapped={isWrapped}
            />
            <PostBack link={link} />
            <div className="get-code">
                <div className="get-code__item">
                    <div className="get-code__name text">
                        Get it:
                    </div>
                    <Input name="link"/>
                    <Button className="link__copy">
                        COPY
                    </Button>
                </div>
            </div>
            <PromoLinkInfo
                type={type}
                lang={lang}
                promoName={link.name}
            />
        </div>
    );
}

PromoLink.propTypes = {
    hash: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    referralState: PropTypes.object,
    getRefAction: PropTypes.func.isRequired,
    isWrapped: PropTypes.object,
    resetPromo: PropTypes.func.isRequired,
};

export default connect(
    ({ referralState: { data: link, loading } }) => ({ link, loading }),
)(PromoLink);
