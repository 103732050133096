import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import ArrowDropdown from '../../../ArrowDropdown';
import PostBackForm from './PostBackForm';
import './PostBack.scss';

function PostBack({ link: { id }, hash }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="postBack">
            <div className="postBack__title d-flex">
                <div
                    className="postBack__arrow"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <ArrowDropdown>
                        <span className="text-small">Set up postback</span>
                    </ArrowDropdown>
                </div>
                <a href="/#" className="text-small">
                    What is POST-BACK?
                </a>
            </div>
            <Collapse in={isOpen}>
                <div>
                    <PostBackForm
                        id={id}
                        hash={hash}
                    />
                </div>
            </Collapse>
        </div>
    );
}

PostBack.propTypes = {
    link: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    hash: PropTypes.string.isRequired
};

export default connect(
    ({ logInfo: { hash } }) => ({ hash })
)(PostBack);