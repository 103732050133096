import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ShortPreloader.scss';

const ShortPreloader = ({ className }) => (
    <div className={classNames('short-preloader', className)}>
        <div className="short-preloader__wrapper">
            <div className="small1">
                <div className="ball smallball1" />
                <div className="ball smallball2" />
                <div className="ball smallball3" />
                <div className="ball smallball4" />
            </div>
            <div className="small2">
                <div className="ball smallball5" />
                <div className="ball smallball6" />
                <div className="ball smallball7" />
                <div className="ball smallball8" />
            </div>
            <div className="bigcon">
                <div className="big ball" />
            </div>
        </div>
    </div>
);

ShortPreloader.propTypes = {
    className: PropTypes.string,
};
ShortPreloader.defaultProps = {
    className: null,
};

export default ShortPreloader;
