import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import chips from '../../../assets/img/happy_chips.png';

function TestimonialItem ({ location: { id }, getItem, item }) {

    useEffect( () => {
        getItem(id);
        // eslint-disable-next-line
    },[]);

    return (
        <div className="testimonial">
            <img src={chips} width="100%" alt="chips" />
            <div className="container">
                <div className="mb-3 text-head testimonial__title">
                    {item && (item[0].logo ? <img src={item[0].logo} alt='' /> : item[0].title)}
                </div>
                <div className="testimonial__text text">
                    {item && item[0].text}
                </div>
                <div className="text testimonials__brand mt-3 mb-3">
                    <a href={`https://${item && item[0].brand}`} target="blank">{item && item[0].brand}</a>
                </div>
                <div className="testimonials__brand">
                    <Link
                        to="/testimonials"
                        className="more_btn news-item__btn"
                    >
                        read all testimonials
                    </Link>
                </div>
            </div>
        </div>
    );
}

TestimonialItem.propTypes = {
    id: PropTypes.number,
    item: PropTypes.array,
    getItem: PropTypes.func.isRequired,
};

const mapStateToProps = ({ testimonials: { testimonialItem: item } }) => ({ item });
const mapDispatchToProps = dispatch => ({
    getItem: ( id = 0 ) => dispatch({
        type: 'GET_ITEM',
        payload: id
    })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestimonialItem);
