import keyMirror from 'keymirror';
import d_link from '../assets/img/d-link.png';
import t_link from '../assets/img/terms.png';

const promoTypes = keyMirror({
    direct: null,
    landings: null,
    brandings: null,
    banners: null,
    video: null,
    terms: null,
});

const promoPages = {
    brand: 1,
    type: 2,
    layout: 3,
    delivery: 4,
    final: 5
};

const availableLangs = [
    { value: '', label: 'Select language' },
    { value: 'ru', label: 'Russian' },
    { value: 'en', label: 'English' },
    { value: 'de', label: 'German' },
    { value: 'fi', label: 'Finland' },
    { value: 'no', label: 'Norway' },
    { value: 'jp', label: 'Japan' },
];

const availableTypes = [
    { value: '', label: 'Select type' },
    { value: 'static', label: 'Static' },
    { value: 'gif', label: 'Gif' },
    { value: 'html5', label: 'Html' },
];

const availableForms = [
    { value: '', label: 'Select form' },
    { value: 'vertical', label: 'Vertical' },
    { value: 'horizontal', label: 'Horizontal' },
    { value: 'square', label: 'Square' },
];

const directLink = [
    {
        name: 'ru',
        type: promoTypes.direct,
        preview: d_link,
        link: 'https://www.kingbillycasino.com/',
        lang: 'ru'
    },
    {
        name: 'en',
        type: promoTypes.direct,
        preview: d_link,
        link: 'https://www.kingbillycasino.com/',
        lang: 'en'
    },
    {
        name: 'fi',
        type: promoTypes.direct,
        preview: d_link,
        link: 'https://www.kingbillycasino.com/fi',
        lang: 'fi'
    },
    {
        name: 'no',
        type: promoTypes.direct,
        preview: d_link,
        link: 'https://www.kingbillycasino.com/no',
        lang: 'no'
    },
    {
        name: 'de',
        type: promoTypes.direct,
        preview: d_link,
        link: 'https://www.kingbillycasino.com/de',
        lang: 'de'
    },
    {
        name: 'jp',
        type: promoTypes.direct,
        preview: d_link,
        link: 'https://www.kingbillycasino.com/jp',
        lang: 'jp'
    },
    {
        name: 'terms_de',
        type: promoTypes.terms,
        preview: t_link,
        link: 'https://www.kingbillycasino.com/de/bonus-terms-conditions',
        lang: 'de'
    },
    {
        name: 'terms_no',
        type: promoTypes.terms,
        preview: t_link,
        link: 'https://www.kingbillycasino.com/no/bonus-terms-conditions',
        lang: 'no'
    },
    {
        name: 'terms_fi',
        type: promoTypes.terms,
        preview: t_link,
        link: 'https://www.kingbillycasino.com/fi/bonus-terms-conditions',
        lang: 'fi'
    },
    {
        name: 'terms_en',
        type: promoTypes.terms,
        preview: t_link,
        link: 'https://www.kingbillycasino.com/en/bonus-terms-conditions',
        lang: 'en'
    },
];

export {
    availableLangs,
    availableForms,
    availableTypes,
    promoTypes,
    promoPages,
    directLink,
};
