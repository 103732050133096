import React from 'react';
import PropTypes from 'prop-types';
import { default as CustomSelect } from 'react-select';
import FieldLabel from '../FieldLabel';
import './Select.scss';

const Select = (props) => {
    const {
        className,
        value,
        options,
        label,
        labelClassName,
        placeholder,
        isMulti,
        isSearchable,
        setValue,
        onChange,
        //defaultValue,
        //isDisabled,
        args
    } = props;

    return (
        <div className="select-wrapper">
            {
                label ?
                    <FieldLabel
                        className={labelClassName}
                        label={label}
                        name={label}
                    />: null
            }
            <CustomSelect
                className={`select-custom ${className}`}
                classNamePrefix="select-custom"
                value={value}
                options={options}
                isMulti={isMulti}
                isSearchable={isSearchable}
                placeholder={placeholder}
                onChange={ onChange ? e => setValue(e) : null}
                //defaultValue={defaultValue}
                //isDisabled={isDisabled}
                //menuIsOpen
                {...args}
            />
        </div>
    )
};

Select.defaultProps = {
    label: null,
    labelClassName: null,
    className: null,
    placeholder: 'Select',
    isMulti: false,
    isSearchable: false,
    onChange: false,
    //defaultValue: null,
    isDisabled: false,
    value: null
};

Select.propTypes = {
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    //name: PropTypes.string.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    onChange: PropTypes.bool,
    //defaultValue: PropTypes.array,
    isDisabled: PropTypes.bool
};

export default Select;