import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FieldLabel from '../FieldLabel';
import './Input.scss';

const Input = props => {
    const {
        label,
        labelClassName,
        name,
        className,
        inputClassName,
        refer,
        ...rest
    } = props;

    return (
        <div className={classNames('field', className)}>
            {label ?
                <FieldLabel
                    className={labelClassName}
                    label={label}
                    name={name}
                /> :
                null}
            <input
                className={classNames('field__input', inputClassName)}
                name={name}
                ref={refer}
                {...rest}
            />
        </div>
    )
};

Input.defaultProps = {
    type: 'text',
    label: null,
    labelClassName: null,
    className: null,
    inputClassName: null,
};

Input.propTypes = {
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    refer: PropTypes.func,
};

export default Input;