import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm from "react-hook-form";
import IsError from '../../IsError';
import {Input, Select} from '../../Form';
import LoadButton from '../../Button/LoadButton';
import { setPostBack } from '../../../actions';
import './PostbackForm.scss';

function PostbackForm({ dropList, setPostBack, postBack: { loading, changed, error } }) {
    const [link, setLink] = useState(null);
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (args) => {
        setPostBack(link.value, args);
    };

    useEffect(() => {
        if ( changed ) {
            setLink({});
            reset();
        }
        // eslint-disable-next-line
    }, [changed]);

    return (
        <form
            className="row p_back__form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="col-lg-4">
                <Select
                    label="Select Brand"
                    name="Brands"
                    options={[{value: 'king-billy', label: 'King Billy'}]}
                    value={{value: 'king-billy', label: 'King Billy'}}
                />
                <Select
                    value={link}
                    //className="mb-0"
                    label="Media-name / UID"
                    name="Brands"
                    isSearchable
                    options={dropList}
                    setValue={(brand) => setLink(brand)}
                    onChange
                    placeholder="Select"
                />
            </div>
            <div className="col-lg-4">
                <Input
                    label="Registration URL"
                    name="changereg"
                    refer={register}
                    placeholder="Set Registration URL"
                />
                <Input
                    //className="mb-0"
                    label="First deposit URL"
                    name="changedep"
                    refer={register}
                    placeholder="Set First deposit URL"
                />
            </div>
            <div className="col-lg-4">
                <Input
                    label="Qualification URL"
                    name="changeaccudep"
                    refer={register}
                    placeholder="Set Qualification URL"
                />
                <LoadButton
                    className="p_back__submit"
                    isLoad={loading}
                >
                    APPLY
                </LoadButton>
            </div>
            <IsError errorText={error ? 'try again': ''} />
        </form>
    );
}

PostbackForm.propTypes = {
    hash: PropTypes.string,
    dropList: PropTypes.arrayOf(PropTypes.object),
    setPostBack: PropTypes.func.isRequired,
    postBack: PropTypes.shape({
        loading: PropTypes.bool,
        changed: PropTypes.bool,
        error: PropTypes.bool,
    }).isRequired
};

const mapDispatchToProps = ( dispatch, { hash } ) => ({
    setPostBack: (id, fields) => {
        for ( let item in fields  ) {
            fields[item].length &&
            dispatch(setPostBack({
                id,
                hash,
                action: item,
                url: fields[item]
            }));
        }
    }
});

export default connect(
    null,
    mapDispatchToProps
)(PostbackForm);