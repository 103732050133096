import axios from 'axios';
import { handler } from '../utils';
import {
    GET_LINKID_LOADING,
    GET_LINKID_SUCCESS,
    GET_LINKID_ERROR,
    EDIT_REF_LOADING,
    EDIT_REF_SUCCESS,
    EDIT_REF_ERROR,
} from '../constants/actionTypes';

const URL_OFFER = 'https://web.lucky4winners.com/api/?type=offer';
const URL_CREATIVES = 'https://web.lucky4winners.com/api/?type=creatives';

export const layoutsAction = params => {
    return dispatch => {
        dispatch(handler.REQUEST('LAYOUTS'));
        axios({
            method: 'post',
            url: URL_CREATIVES,
            data: params,
            headers: {
                'content-Type': 'application/json',
            }
        })
            .then( res => dispatch(handler.SUCCESS('LAYOUTS', res)) )
            .catch( err => dispatch(handler.FAILURE('LAYOUTS', err)) )
    }
};

export const deliveryAction = params => {
    return dispatch => {
        dispatch(handler.REQUEST('DELIVERY'));
        axios.post(URL_CREATIVES, params, {
            headers: { 'content-Type': 'application/json' }
        })
            .then( res => dispatch(handler.SUCCESS('DELIVERY', res)) )
            .catch( err => dispatch(handler.FAILURE('DELIVERY', err)) )
    }

};

export const getRefAction = params => {
    return dispatch => {
        dispatch({ type: GET_LINKID_LOADING });
        axios.post(URL_OFFER, params, {
            headers: {
                'content-Type': 'application/json',
            }
        })
            .then( res => dispatch({ type: GET_LINKID_SUCCESS, payload: res }) )
            .catch( err => dispatch({ type: GET_LINKID_ERROR, payload: err }) )
    }
};

export const editRefAction = params => {
    return dispatch => {
        dispatch({ type: EDIT_REF_LOADING });
        axios.post(URL_OFFER, params, {
            headers: {
                'content-Type': 'application/json',
            }
        })
            .then( res =>
                dispatch({
                    type: EDIT_REF_SUCCESS,
                    payload: res
                })
            )
            .then( () =>
                dispatch({
                    type: "EDIT_PROMO_NAME",
                    payload: {
                        id: params.id,
                        name: params.name,
                    }
                })
            )
            .catch( err =>
                dispatch({
                    type: EDIT_REF_ERROR,
                    payload: err
                })
            )
    }
};
