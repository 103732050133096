import React from 'react';
import PropTypes from 'prop-types'
import FakeCheckbox from '../../../../Form/FakeCheckbox';
import { promoTypes } from '../../../../../constants';
import './ReferrFilter.scss';

function ReferrFilter({ className, refType, setRefType }) {
    return (
        <>
            <div className={className}>
                <div
                    className="text-small ref-check"
                    onClick={() => setRefType(promoTypes.landings)}
                >
                    <FakeCheckbox isActive={ refType === promoTypes.landings }>
                        select LANDING page
                    </FakeCheckbox>
                </div>
            </div>
            <div className={className} >
                <div
                    className="text-small ref-check"
                    onClick={() => setRefType(promoTypes.direct)}
                >
                    <FakeCheckbox isActive={ refType === promoTypes.direct }>
                        select direct link
                    </FakeCheckbox>
                </div>
            </div>
        </>
    );
}

ReferrFilter.propTypes = {
    className: PropTypes.string.isRequired,
    refType: PropTypes.string,
    setReferr: PropTypes.func
};

export default ReferrFilter;