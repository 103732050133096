const handleFrom = ( from, date ) => from(date);

const handleTo = ( from, date ) => from(date);

const handleSelectToday = (from, to) => {
    from(new Date());
    to(new Date());
};

const handleSelectWeek = (from, to) => {
    from(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    to(new Date());
};

const handleSelectMonth = (from , to) => {
    const date = new Date();
    const firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);

    from(firstMonthDay);
    to(new Date());
};

export {
    handleFrom,
    handleTo,
    handleSelectToday,
    handleSelectWeek,
    handleSelectMonth
};