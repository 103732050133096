import React from 'react';
import BrandDescription from './BrandDescription';
import billy from '../../../assets/img/KB_image.png';
import KB from '../../../assets/img/KB_LOGO_color.svg';
import './BrandsSlider.scss';

function BrandsSlider() {

    return (
        <div className="brands bg_crown">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4 brand__item">
                        <div className="brand__img">
                            <img src={billy} alt="" />
                        </div>
                        <div className="brand__logo">
                            <img
                                className="brand__logo-img"
                                src={KB}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 mt-3 mt-lg-5 text">
                        <div className="mb-2">
                            Try on a crown at King Billy online casino
                            created to entertain citizens and nobility by
                            offering high-quality casino games and generous
                            promotions. Started in 2017, King Billy got
                            recognition of players from all around the globe
                            because of its exceptional video slots, an array
                            of withdrawal methods, and round-the-clock support.
                        </div>
                        <div className="mb-2">
                            King Billy offers casino games from such well-known
                            providers as NetEnt, Microgaming, Yggdrasil, and
                            others. It is a regulated casino licensed by Curacao,
                            so visitors can play safely and perform money
                            transactions with confidence.
                        </div>

                        <div className="">
                            King Billy’s kingdom is lavish of rewards which players
                            can get by taking part in tournaments and promotions.
                            Moreover, every citizen of this joyful land can become
                            a king or a queen after joining the royal VIP Club.
                        </div>
                    </div>
                </div>
                <BrandDescription />
            </div>
        </div>
    )
}

export default BrandsSlider;
