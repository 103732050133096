import { testimonials } from '../testRestAPI';

const initialState = {
    testimonials,
    testimonialItem: null
};

const handlers = {
    GET_ALL: (state) => ({
        ...state,
        testimonials,
        testimonialItem: null,
    }),
    GET_ITEM: ( state,  { payload: id }) => {
        return {
            ...state,
            testimonialItem: state.testimonials.filter(item => item.id === id)
        }
    },
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;

    return handle(state, action);
};