import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Board.scss';

const Board = ({children, className, title}) => {
    return (
        <div className={classNames('board', className)}>
            <div className="text-head board__title">{title}</div>
            {children}
        </div>
    );
};

Board.defaultProps = {
    className: null,
    title: 'Default'
};

Board.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    className: PropTypes.string
};

export default Board;
