import {useState, useEffect} from 'react';
import keyMirror from 'keymirror';

const themeParams = keyMirror({
    dark: null,
    light: null
});

export const useTheme = () => {
    const [theme, setTheme] = useState(themeParams.dark);
    const htmlTag = document.getElementsByTagName("html")[0];

    const setThemeHandler = mode => {
        setTheme(mode);
        htmlTag.setAttribute('theme', themeParams[mode]);
        window.localStorage.setItem('theme', themeParams[mode]);
    };

    useEffect(() => {
        const localTheme = localStorage.getItem('theme');

        if (localTheme) {
            setThemeHandler(localTheme)
        } else {
            setThemeHandler(themeParams.dark);
        }
        // eslint-disable-next-line
    }, [theme]);

    const toggleTheme = () => {
        if (htmlTag.getAttribute('theme') === themeParams.light) {
            setThemeHandler(themeParams.dark)
        } else {
            setThemeHandler(themeParams.light);
        }
    };

    return {theme, toggleTheme}
};