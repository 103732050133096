import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useForm from 'react-hook-form';
import Input from '../../../Form/Input';
import CheckBox from '../../../Form/CheckBox';
import Button from '../../../Button';
import AuthError from '../../AuthError';
import { logIn } from '../../../../actions'
import './SignInForm.scss';

const SignInForm = ({ logIn, isError }) => {
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (args) => logIn(args);

    return (
        <form
            className="text-center sign-in__form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Input
                type="text"
                name="login"
                placeholder="Login"
                refer={register({
                    required: true,
                    minLength: 6,
                })}
            />
            <AuthError error={errors.login} />
            <Input
                type="password"
                name="password"
                placeholder="Password"
                refer={register({
                    required: true,
                    minLength: 6
                })}
            />
            <AuthError error={errors.password} />
            <div className="sign-in__remember">
                <CheckBox
                    name="isLocal"
                    refer={register}
                />
                <span className="text-small">
                    remember me
                </span>
            </div>
            <Button>
                log-in
            </Button>
            <div className="sign-in__serv-err">
                {isError}
            </div>
        </form>
    );
};

SignInForm.propTypes = {
    logIn: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    logIn: ({ login, password }) => {

        sessionStorage.setItem('session', true);

        return dispatch(logIn(login, password))
    }
});

export default compose(
    withRouter,
    connect(null, mapDispatchToProps)
)(SignInForm);
