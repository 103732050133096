import React, { useState } from 'react';
import PropTypes from "prop-types";
import FakeCheckbox from '../../../Form/FakeCheckbox';
import ShortPreloader from '../../../ShortPreloader';
import './BrandingLayout.scss';

function BrandingLayout({ layout, data: { loading, data }, setPromo }) {
    const [active, setActive] = useState(layout);

    const handleClick = l => () => {
        setActive(l.name);
        setPromo(l.name, 'layout');
        setPromo({link: l.link}, 'isWrapped');
    };

    if ( loading ) {
        return <ShortPreloader />;
    }

    return (
        <div className="row layout-branding">
            {data.map((layouts, id) => (
                <div
                    key={id}
                    className="col-12 col-md-6 layout-branding__item"
                    onClick={handleClick(layouts)}
                >
                    <div className="layout-branding__img-wrap">
                        <FakeCheckbox isActive={active === layouts.name}>
                            <img
                                className="layout-branding__image"
                                src={layouts.link}
                                alt=""
                            />
                        </FakeCheckbox>
                    </div>
                </div>
            ))}
        </div>
    );
}

BrandingLayout.propTypes = {
    data: PropTypes.shape({
        loading: PropTypes.bool,
        data: PropTypes.array,
    }).isRequired,
    setPromo: PropTypes.func.isRequired,
    layout: PropTypes.string.isRequired,
};

export default BrandingLayout;
