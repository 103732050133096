import { connect } from 'react-redux';
import MoneyBalance from './MoneyBalance';

export default connect(
    ({
         logInfo: {
             data: {
                 balance,
                 cparate,
                 refshare,
                 today
             }
         }
     }) => ({ balance, cparate, refshare, today })
)(MoneyBalance);