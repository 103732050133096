import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import useForm from 'react-hook-form';
import { Input, Select } from '../../Form';
import Button from '../../Button';
import './PromoFilter.scss';

const options = [
    { value: 'kingBilly', label: 'King Billy' },
];

function PromoFilter({ className, filterPromo }) {
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        filterPromo(data.sortByName);
    };

    return (
        <div className={classNames('promo__filter', className)}>
            <form
                className="promo__form row"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="col-12 col-lg-6">
                    <Select
                        label="Sort by brand"
                        className="mb-0"
                        name="sortByUid"
                        options={options}
                        value={[{value: 'kingBilly', label: 'King Billy'}]}
                    />
                </div>
                <div className="col-12 col-lg-6">
                    <Input
                        label="Sort by uid"
                        name="sortByName"
                        className="mb-0"
                        inputClassName="promo__input"
                        placeholder="Enter promo-code name"
                        refer={register}
                    />
                </div>
                <div className="col-12 text-right">
                    <Button className="mt-3">filter</Button>
                </div>
            </form>
        </div>
    )
}

PromoFilter.defaultProps = {
    className: null
};

PromoFilter.propTypes = {
    className: PropTypes.string,
    filterPromo: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        filterPromo: ( uid ) => {
            dispatch({
                type: "FILTER_PROMO",
                payload: uid
            })
        }
    }
};

export default  connect(null, mapDispatchToProps )(PromoFilter);