import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import Input from '../../Form/Input';
import Button from '../../Button';
import AuthError from "../../Auth/AuthError";
import IsError from "../../IsError";

function ProfileForm(props) {
    const {
        hash, className,
        changePassword,
        isChanged, changeErr
    } = props;

    const { register, handleSubmit, watch, errors, reset } = useForm();

    useEffect( () => {
        if ( isChanged ) {
            reset();
        }
        // eslint-disable-next-line
    }, [isChanged]);


    const onSubmit = data => {
        changePassword({ hash, ...data })
    };

    return (
        <form
            className={`profile__form ${className}`}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            <Input
                label="Old password"
                labelClassName="profile__lable"
                inputClassName="profile__input"
                type="password"
                name="old"
                refer={register({required: true})}
                autoComplete="off"
            />
            <AuthError error={errors.old} />
            <Input
                label="New password"
                labelClassName="profile__lable"
                inputClassName="profile__input"
                type="password"
                name="new"
                refer={register({required: true, minLength: 6,})}
            />
            <AuthError error={errors.new} />
            <Input
                label="Confirm password"
                labelClassName="profile__lable"
                inputClassName="profile__input"
                type="password"
                name="confirm"
                refer={register({
                    required: true,
                    validate: val => val === watch('new')
                })}
                autoComplete="off"

            />
            <AuthError error={errors.confirm} />
            <Button
                className={`mt-3 ${isChanged && 'btn_disabled'}`}
                disabled={isChanged && true}
            >
                Apply
            </Button>
            <IsError
                className="mt-3"
                errorText={isChanged ? 'password changed': '' }
            />
            <IsError
                className="mt-3"
                errorText={changeErr}
            />
        </form>
    )
}

ProfileForm.defaultProps = {
    className: null,
};

ProfileForm.propTypes = {
    hash: PropTypes.string.isRequired,
    className: PropTypes.string,
    isChanged: PropTypes.bool.isRequired,
    changeErr: PropTypes.string.isRequired,
    changePassword: PropTypes.func.isRequired,
};

export default ProfileForm;