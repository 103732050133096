import { connect } from 'react-redux';
import Profile from './Profile';
import { getPayments, changePassword } from '../../actions';

export default connect(
    ({
         logInfo: {
             hash,
             data: {
                 cparate,
                 refshare,
             }
         },
         passStatus: {
             changed,
             changeErr
         },
         paymentsList: {
             data,
             loading,
             error,
             payInfo
         },
     }) => ({ data, loading, error, changed, changeErr, hash, payInfo, cparate, refshare }),
        { getPayments, changePassword }
)(Profile);