import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import { Input } from '../../../Form';
import Button from '../../../Button';
import { getPayments } from '../../../../actions';
import './PaymentInfo.scss';

function PaymentInfo({ className, payInfo, setPay }) {

    const { register, handleSubmit } = useForm();

    const onSubmit = ({ paymentinfo }) => {
        setPay( paymentinfo )
    };

    if ( !payInfo ) {
        return (
            <form
                className={`${className}`}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Input
                    label="Enter your payments info:"
                    type="text"
                    name="paymentinfo"
                    className="payment__field"
                    inputClassName="payment__input"
                    refer={register}
                />
                <Button className="payment__form-btn">
                    save
                </Button>
            </form>
        );
    }

    return (
        <div className={`${className}`}>
            <Input
                label="Your payments info:"
                inputClassName="payment__input payment__input-info"
                name="payInfo"
                defaultValue={payInfo}
                readOnly
            />
            <div className="text-small">
                to change payment method, please, contact your account manager
            </div>
        </div>
    )
}

PaymentInfo.propTypes = {
    className: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    payInfo: PropTypes.string.isRequired,
    setPay: PropTypes.func.isRequired
};

const mapDispathToProps = (dispatch, { hash }) => ({
   setPay: paymentinfo => dispatch(getPayments({
        hash,
        type: 'changeinfo',
        paymentinfo
    }))
});

export default connect(
    null,
    mapDispathToProps
)(PaymentInfo);