import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm from 'react-hook-form';
import Input from '../../../Form/Input';
import AuthError from '../../../Auth/AuthError';
import LoadButton from '../../../Button/LoadButton';
import { sendContact } from '../../../../actions';
import './ContactForm.scss';

function ContactForm({ className, sendContact, status, loading }) {
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        sendContact(data)
    };

    return (
            <form
                className={`contact-form ${className}`}
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
            >
                <Input
                    type="text"
                    name="name"
                    placeholder="Name"
                    inputClassName="contact__input"
                    refer={register({
                        required: true,
                        pattern: /^[A-z0-9]+$/
                    })}
                    autoComplete="nope"
                />
                <AuthError error={errors.name} />
                <Input
                    type="text"
                    name="email"
                    placeholder="Email"
                    inputClassName="contact__input"
                    refer={register({
                        required: true,
                        pattern: /\S+@\S+\.\S+/
                    })}
                />
                <AuthError error={errors.email} />
                <textarea
                    name="message"
                    className="contact__textarea"
                    placeholder="Your message"
                    ref={register({
                        required: true,
                    })}
                />
                <AuthError error={errors.message} />
                <LoadButton
                    className="mt-3 button-bg"
                    isLoad={loading}
                >
                    submit
                </LoadButton>
            </form>
    );
}

ContactForm.propTypes = {
    className: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => ({
    sendContact: (params) => {
        return dispatch(sendContact(params));
    }
});

export default connect(
    ({ contactStatus: { status, loading } }) => ({ status, loading }),
    mapDispatchToProps
)(ContactForm);