import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as LogoIco} from '../../../assets/img/LOGO-mob.svg';
import './Logo.scss';

const Logo = ({onClick, className}) => (
    <div className={`header-logo ${className}`}>
        <LogoIco onClick={onClick} />
    </div>
);

Logo.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default Logo;
