import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import './CustomTooltip.scss';

const CustomTooltip = ({fValue, sValue, ...args}) => {
    const date = get(args, 'payload[0].payload.name'),
        bar = get(args, 'payload[0].payload.bar'),
        line = get(args, 'payload[0].payload.line');

    return (
        <div className="customized-tooltip">
            <div className="customized-tooltip__label text-small">
                {date}
            </div>
            <div className="customized-tooltip__wrapper">
                <div className="customized-tooltip__names">
                    {!fValue ||
                    <div className="customized-tooltip__names--item text-small">
                        {fValue}
                    </div>
                    }
                    <div className="customized-tooltip__names--item text-small">
                        {sValue}
                    </div>
                </div>
                <div className="customized-tooltip__values">
                    {!fValue ||
                    <div className="customized-tooltip__values--item text-small">
                        {parseInt(bar)} {fValue === 'NGR' ? '€' : null}
                    </div>
                    }
                    <div className="customized-tooltip__values--item text-small">
                        {parseInt(line)} {sValue === 'NGR' ? '€' : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

CustomTooltip.defaultProps = {
    fValue: null,
    sValue: null
};

CustomTooltip.propTypes = {
    fValue: PropTypes.string,
    sValue: PropTypes.string,
};

export default CustomTooltip;
