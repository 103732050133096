import React, { useContext } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { AuthModalContext } from '../../../context';
import Button from '../../Button';
import banner from '../../../assets/img/banner.png';
import s from './BannerSlider.module.scss';

function BannerSlider() {

    const { handleSignUpModal: openModal } = useContext(AuthModalContext);

    return (
        <Carousel controls={false} indicators={false}>
            <Carousel.Item
                className={s.height}
                style={{backgroundImage: `url(${banner})`}}
            >
                <div className={`container ${s.wrap}`}>
                    <div className={s.title}>KINGS CLUB</div>
                    <div className={`mt-4 mb-4 ${s.text}`}>
                        AFFILIATES REFERRAL <br/>
                        FOR THE UPSCALE ROYAL <br/>
                        REVSHARE UP TO 50%
                    </div>
                    <Button onClick={openModal} className="button-bg">
                        join now
                    </Button>
                </div>
            </Carousel.Item>
        </Carousel>
    )
}

export default BannerSlider;
