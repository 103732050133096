const initialState = {
    status: null,
    loading: false,
};

const newsReducer = ( state = initialState, { type, payload } ) => {
    switch (type) {
        case "SEND_LOAD":
            return {
                ...state,
                loading: true,
            };
        case "SEND_SUCCESS":
            return {
                ...state,
                status: payload.status,
                loading: false
            };
        case "SEND_ERROR":
            return {
                ...state,
                status: 'try again...',
                loading: false
            };
        default:
            return state;
    }
};

export default newsReducer;