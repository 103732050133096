import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function Clipboard({ text, className, clip, clipped }) {
    const [ copied, setCopied ] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCopied(false)
        }, 444);

        return () => clearTimeout(timer);
    });

    return (
        <CopyToClipboard
            onCopy={() => setCopied(true) }
            text={text}
        >
            <div className={className}>
                { copied ? clipped : clip }
            </div>
        </CopyToClipboard>
    )
}

Clipboard.defaultProps = {
    className: '',
    clip: 'COPY',
    clipped: 'COPIED'
};

Clipboard.propTypes = {
    className: PropTypes.string.isRequired,
    text: PropTypes.string,
    clip: PropTypes.string,
    clipped: PropTypes.string,
};

export default Clipboard;