import React, {memo} from 'react';
import { NavLink } from 'react-router-dom';
import { NavHashLink as Anchor } from 'react-router-hash-link';
import {ReactComponent as LogoIco} from '../../assets/img/LOGO_footer.svg';
import './Footer.scss';

const menu = [
    {url: '/policy', urlName: 'Data protection policy'},
    {url: '/terms', urlName: 'T&C'},
    {url: '/faq', urlName: 'FAQ'},
];

const Footer = memo(() => (
    <div className="footer bg_crown">
        <div className="pt-3 pb-3 pt-md-0 container">
            <div className="d-none d-md-flex mb-3 footer__navigation">
                {menu.map(item => (
                    <NavLink key={item.url} to={item.url} className="footer__link text">
                        {item.urlName}
                    </NavLink>
                ))}
                <Anchor to="/#contacts" className="footer__link text">
                    CONTACTS
                </Anchor>
            </div>
            <div className="row">
                <div className="col-12 col-md-3 order-md-1 footer__logo-wrap">
                    <LogoIco />
                </div>
                <div className="col-12 col-md-9 footer__copy-text text-small">
                    Copyright © 2019 All Rights Reserved. Kings Club is owned and operated by BOKAYA TRADE LTD.
                </div>
            </div>
            <div className="footer__copy">
                <span>&copy; kingsclub</span>
            </div>
        </div>
    </div>
));

export default Footer;
