export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const PIECE_OF_NEWS = 'PIECE_OF_NEWS';
export const STATS_REQUEST = 'STATS_REQUEST';
export const STATS_SUCCESS = 'STATS_SUCCESS';
export const STATS_FAILURE = 'STATS_FAILURE';
export const FILTER_STATS_COLUMNS = 'FILTER_STATS_COLUMNS';
export const LAYOUTS_REQUEST = 'LAYOUTS_REQUEST';
export const LAYOUTS_SUCCESS = 'LAYOUTS_SUCCESS';
export const LAYOUTS_FAILURE = 'LAYOUTS_FAILURE';
export const GET_LINKID_LOADING = 'GET_LINKID_LOADING';
export const GET_LINKID_SUCCESS = 'GET_LINKID_SUCCESS';
export const GET_LINKID_ERROR = 'GET_LINKID_ERROR';
export const DELIVERY_REQUEST = 'DELIVERY_REQUEST';
export const DELIVERY_SUCCESS = 'DELIVERY_SUCCESS';
export const DELIVERY_FAILURE = 'DELIVERY_FAILURE';
export const EDIT_REF_LOADING = 'EDIT_REF_LOADING';
export const EDIT_REF_SUCCESS = 'EDIT_REF_SUCCESS';
export const EDIT_REF_ERROR = 'EDIT_REF_ERROR';
export const POST_BACK_REQUEST = 'POST_BACK_REQUEST';
export const POST_BACK_SUCCESS = 'POST_BACK_SUCCESS';
export const POST_BACK_FAILURE = 'POST_BACK_FAILURE';
export const GET_PROMO_LOADING = 'GET_PROMO_LOADING';
export const GET_PROMO_SUCCESS = 'GET_PROMO_SUCCESS';
export const GET_PROMO_ERROR = 'GET_PROMO_ERROR';
export const REMOVE_PROMO_LOADING = 'REMOVE_PROMO_LOADING';
export const REMOVE_PROMO_SUCCESS = 'REMOVE_PROMO_SUCCESS';
export const REMOVE_PROMO_ERROR = 'REMOVE_PROMO_ERROR';
export const RELIVE_PROMO_LOADING = 'RELIVE_PROMO_LOADING';
export const RELIVE_PROMO_SUCCESS = 'RELIVE_PROMO_SUCCESS';
export const RELIVE_PROMO_ERROR = 'RELIVE_PROMO_ERROR';
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';