import React, { useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Button from '../../Button';
import logo_3 from '../../../assets/img/REV_SHARE_icon.svg';
import logo_2 from '../../../assets/img/CPA_icon.svg';
import logo_1 from '../../../assets/img/HYBRID_icon.svg';
import logo_4 from '../../../assets/img/HYBRID_icon12.svg';
import { AuthModalContext } from '../../../context';
import './Commission.scss';

const content = [
    {
        eventKey: "home",
        img: logo_1,
        title: "CPA",
        text: "CPA is a fixed price for every referred player. This price can scale up to € 400 depending on the geolocation, casino brand, quality of traffic, and conditions of getting paid. \n" +
            "\n" +
            "Player qualification requirements:\n" +
            " 1. Total deposit sum should comprise at least €20. \n" +
            " 2. A new player should make a deposit in a week after registration. \n"
    },
    {
        eventKey: "home1",
        img: logo_2,
        title: "REVSHARE",
        text: "RevShare is a fair and transparent commission plan based on the number of new players you refer to our online casinos monthly. We offer a lifetime commission - 50% of the total Net Gaming Revenue and no negative carryover. Truly royal conditions!  \n" +
            "\n" +
            "We calculate your RevShare according to the formula:\n" +
            "NGR = ((100% - admin fee) x (bets - wins -bonuses)) x RevShare %; \n" +
            "Admin Fees = License fee + games provider fee + platform fee. \n"
    },
    {
        eventKey: "home2",
        img: logo_3,
        title: "HYBRID",
        text: "The hybrid plan is a combination of CPA and RevShare where the standard commission is calculated as follows: €100 CPA + 25% RevShare. The minimal deposit for getting the CPA part is €20. "
    },
    {
        eventKey: "home3",
        img: logo_4,
        title: "Sub-Affiliate",
        text: "Kings Club is rich for opportunities in affiliate business. With our sub-affiliate program, you can earn by engaging new partners to work with us. The basic rate comprises 5% of the income generated by the Partners you invited. You can track the efficiency of your sub-affiliate program in your dashboard by monitoring detailed statistics of your performance. The data can be filtered by dates, invites, and sub-affiliates. We help you to attract new Partners by providing specially designed promotional materials which you can find in the dashboard. \n"
    }
];

function Commission() {
    const { handleSignUpModal: openModal } = useContext(AuthModalContext);

    return (
        <div className="commission bg_crown" id="commissions">
            <div className="pt-3 pb-3 pt-md-5 pb-md-5 container">
                <div className="container-title">
                    commission
                </div>
                <div className=" pb-3 pb-md-5 text">
                    Any of the commission plans listed above is a subject to
                    an individual alignment. You should contact our affiliate
                    managers in order to negotiate the conditions of collaboration
                    and sign up a partnership agreement with us.
                </div>
                <Tabs defaultActiveKey="home">
                    {content.map(item => (
                        <Tab
                            key={item.eventKey}
                            eventKey={item.eventKey}
                            title={
                                <>
                                    <img src={item.img} alt=""/>
                                    <span className="commission__text">
                                     {item.title}
                                 </span>
                                </>
                            }>
                            <>
                                <div className="mb-3 text-head">
                                    {item.title}
                                </div>
                                <div className="text">
                                    {item.text}
                                </div>
                            </>
                        </Tab>
                    ))}
                </Tabs>
                <Button
                    onClick={openModal}
                    className="mt-5 button-bg"
                >
                    join now
                </Button>
            </div>
        </div>
    )
}

export default Commission;
