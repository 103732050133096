import {
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE,
} from "../constants/actionTypes";

const initialState = {
    id: null,
    loading: false,
    error: '',
};

const regReducer = ( state = initialState, {type, payload} ) => {
    switch (type) {
        case SIGN_UP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SIGN_UP_SUCCESS:
            return {
                ...state,
                id: payload.data.id,
                loading: false,
                error: '',
            };
        case SIGN_UP_FAILURE:
            return {
                ...state,
                error: payload.data.error
            };
        default:
            return state
    }
};

export default regReducer;