import newsImg from './assets/img/Banner_news_700x480.jpg';
import newsImg2 from './assets/img/Banner_news_700x480_2019_awards.jpg';
import norges from './assets/img/logo-partners/norges.png';
import sixlots from './assets/img/logo-partners/sixlots.png';
import laatu from './assets/img/logo-partners/laatu.png';
import rev from './assets/img/logo-partners/reviews.png';
import nz from './assets/img/logo-partners/new_zeland.png';
import nc from './assets/img/logo-partners/newasinos.png';
import martini from './assets/img/logo-partners/martini.png'
import vos from './assets/img/logo-partners/vos.png'
import wise from './assets/img/logo-partners/wisecry.jpg'
import zed from './assets/img/logo-partners/imgpsh.png'

const news = [
    {
        id: 0,
        title: 'Kings Club welcomes new allies',
        thumbnail: newsImg,
        text: 'A new affiliate program Kings Club was launched this fall. Now, kings and queens from all over the globe can join the royal gambling community and start their affiliate casino business. \n' +
            '\n' +
            'The program features generous commission rates and competitive conditions for partnership. \n' +
            '\n' +
            'Kings Club offers any webmaster who can generate high-quality traffic to become their official partner and earn on promoting their online casino products. The offered RevShare can reach up to 50% of the total NGR, while the commissions are calculated with no negative carryover.\n' +
            '\n' +
            'At the moment, Kings Club promotes the main product: kingbillycasino.com focused on players from Europe, Canada, Australia and New Zealand',
        date: '08.07.2019'
    },
    {
        id: 1,
        title: 'BEST CASINO 2019 IN THE ASKGAMBLERS AWARDS!',
        thumbnail: newsImg2,
        text: 'January 2018. The first ever AskGamblers Awards are held by AskGamblers. King Billy Casino wins Best New Casino Award.\n' +
            '\n' +
            'January 2019. The second AskGamblers Awards! King Billy Casino wins Best Casino 3 rd place Award and Player’s Choice 2 nd place Award.\n' +
            '\n' +
            'January 2020. The third AskGamblers Awards! King Billy Casino wins Best Casino Award.\n' +
            '\n' +
            'Let us say this again folks.\n' +
            '\n' +
            'Best. Casino. Award.\n' +
            '\n' +
            'Among the 1,488 casinos featured in the AskGamblers portal, King Billy Casino, your casino got the big one! And we are now leading the all-time list in AskGamblers Awards with 4 awards (Bitstarz Casino is second with 3).\n' +
            '\n' +
            'What an honor! What a joyous event! And most important, what a journey it has been, and still is!\n' +
            '\n' +
            'When we launched on St. Patrick’s Day on Friday, March 17, 2017, we did not have a big name or a big fan base. What did we have?\n' +
            '\n' +
            'We had a team that felt like a family and does so even more today.\n' +
            '\n' +
            'We had the veteran’s experience.\n' +
            '\n' +
            'We had the junior’s enthusiasm.\n' +
            '\n' +
            'We had belief in our ability to offer features never seen before.\n' +
            '\n' +
            'We had a King that rocks.\n' +
            '\n' +
            'And finally we had, and still have the best players in the world.\n' +
            '\n' +
            'We don’t stop here folks. The year is still young and full of more wonders and more awards. Together, we can win them all!\n' +
            '\n' +
            'Long Live King Billy!\n' +
            'Long Live his Citizens!\n' +
            'Play long and prosper!',
        date: '01.20.2020'
    },
];

const testimonials = [
    {
        id: 10,
        logo: zed,
        title: 'onlinecasinozed',
        text: 'We at Online Casino Zed have just begun our partnership with King Billy brand, and at the beginning, we can say that King Billy casino is a great brand with awesome options for Canadian players. Highly recommended!We at Online Casino Zed have just begun our partnership with King Billy brand, and at the beginning, we can say that King Billy casino is a great brand with awesome options for Canadian players. Highly recommended!',
        brand: 'onlinecasinozed.com',
        link: 'onlinecasinozed.com/'
    },
    {
        id: 9,
        logo: wise,
        title: 'wisecryptos',
        text: 'King Billy is a fantastic casino and we have been looking forward to working together with Kings Club for a while. Max has been extremely helpful and fully delivered on all promises and all materials we requested. Highly recommended.',
        brand: 'wisecryptos.com',
        link: 'wisecryptos.com/'
    },
    {
        id: 8,
        logo: vos,
        title: 'vosbonus',
        text: 'The newly launched project FOX BONUS Collective of course launched with King Billy as one of the Flagship casinos to promote. Having success with them in other poject this was a sure thing! - Vosbonus',
        brand: 'vosbonus.com',
        link: 'vosbonus.com/'
    },
    {
        id: 7,
        logo: martini,
        title: 'casinomartini',
        text: 'Met Max from King Billy at one of the iGaming conferences, and decided to start and promote their casino right away at good positions, as i had a great feeling it would perform very well',
        brand: 'casinomartini.com',
        link: 'casinomartini.com/'
    },
    {
        id: 6,
        logo: nc,
        title: 'newcasinos',
        text: 'I have rated King Billy as the # 1 online casino for 2020 due to their slick design, offering excellent support to their players and bonuses with fair wages. We are very pleased to work with KingsClub Affiliates. There conversion rate is amazing! Max has proven to be one of the best affiliate manager with excellent communication skills. I would definitely without a doubt recommend King Billy to other affiliates!',
        brand: 'www.newcasinos.org',
        link: 'www.newcasinos.org/casino/king-billy-casino/'
    },
    {
        id: 5,
        logo: nz,
        title: 'newzealandcasinos',
        text: 'We at newzealandcasinos.nz just started our partnership with KingsClub! For start, we can say that they have a great brand that has a lot of perks to offer players from New Zealand. We are excited and looking forward to work with KingsClub Affiliates! Highly Recommended!',
        brand: 'www.newzealandcasinos.nz'
    },
    {
        id: 4,
        logo: laatu,
        title: '',
        text: 'King Billy provides our customers with an excellent casino experience\n' +
            'and has been a joy to work with from the start. The affiliate team is\n' +
            'very professional and very easy to work with. Highly recommended!',
        brand: 'www.laatukasinot.com'
    },
    {
        id: 0,
        logo: norges,
        title: 'norgescasino',
        text: 'We have just InnerSliderstarted working with King Billy, and so far it has proven to be a fruitful partnership for both. We are looking forward to see where this are going to bring us in the future. The affiliate manager have been professional, and the communication great. We have no reason not to recommend King Billy to other affiliates.',
        brand: 'www.norgescasino.com'
    },
    {
        id: 1,
        logo: sixlots,
        title: 'sixslots',
        text: 'King Billy truly is the royalty of online casinos! Not only we are very excited to promote King Billy to our visitors as one of the top choices, but also we highly appreciate the team of King Billy Affiliates - managers are always helpful and enthusiastic. We\'ve been partners for quite some time now, and we hope to build long-lasting collaboration. Hail to the king!',
        brand: 'www.sixslots.com'
    },
    {
        id: 2,
        logo: rev,
        title: 'casinoreviews',
        text: 'King Billy Casino is a great online casino. Apart from having great design and bonuses, what makes them stand out is that they really care about their players. They are very active in forums and always answer to any complaints. This has resulted in great retention value. We at CasinoReviews highly recommend promoting King Billy Casino.',
        brand: 'www.casinoreviews.net.nz'
    },
    {
        id: 3,
        title: 'Bonuslist',
        text: 'King Billy captures royal grace throughout every aspect – its online casino platform, professional team, and great communication. An outstanding casino experience is something our team at Bonuslist.eu want to give to our visitors.\n' +
            'We appreaciate cooperation with King Billy Casino and hope to bring our readers to king’s entertaining playground. ',
        brand: 'www.bonuslist.eu'
    },
];

export {
    news,
    testimonials,
}
