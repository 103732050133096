import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import renderHTML from 'react-render-html';
import { PreviewContext } from "./previewContext";
import { getPreview } from '../utils';
import './PreviewModal.scss';

function PreviewModal() {
    const {
        isOpen,
        closeModal,
        preview
    } = useContext(PreviewContext);

    return (
        <Modal
            className="preview"
            show={isOpen}
            onHide={closeModal}
        >
            <Modal.Body>
                <div className="text mb-2">Preview</div>
                {renderHTML(`${getPreview(preview)}`)}
            </Modal.Body>
            <Modal.Footer>
                <div
                    className="button-default button-sm"
                    onClick={closeModal}
                >
                    Close
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default PreviewModal