import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import AuthTitle from '../AuthTitle';
import SignUpForm from './SignUpForm';
import AuthFooter from "../AuthFooter";
import SignUpCongrats from './SignUpCongrats';
import { AuthModalContext } from '../../../context';
import logo from '../../../assets/img/LOGO_head.svg';
import './SignUp.scss';

function SignUp({ id: regId, error }) {
    const {
        handleLogInModal,
        isOpenSignUp: isOpen,
        handleSignUpModal: setIsOpen
    } = useContext(AuthModalContext);

    const handleLogIn = async () => {
        setIsOpen(false);
        await handleLogInModal();
    };

    useEffect(() => {
        const url = new URL(window.location.href),
            hasRefId = url.searchParams.get('refid');

        if ( hasRefId && isOpen === false ) {
            setIsOpen(true);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Modal
                show={isOpen}
                className="reg-modal"
                onHide={() => {}}
            >
                <AuthTitle
                    logo={logo}
                    text="REGISTRATION FORM"
                    onClick={() => setIsOpen(!isOpen) }
                />
                {
                    !regId ?
                        <>
                            <SignUpForm isError={error} />
                            <AuthFooter
                                text="ALREADY A MEMBER?"
                                switchLogRegModal={handleLogIn}
                            />
                        </>
                        : <SignUpCongrats/>
                }
            </Modal>
        </>
    );
}

SignUp.propTypes = {
    id: PropTypes.number,
    error: PropTypes.string,
};

export default connect(
    ({regInfo: { id, error }}) => ({id, error})
)(SignUp);