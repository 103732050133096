import axios from "axios";
import { handler } from '../utils';

const URL = 'https://web.lucky4winners.com/api/?type=feedback';

export function sendContact(params) {
    return dispatch => {
        dispatch(handler.REQUEST('SEND'));
        axios({
            method: 'post',
            url: URL,
            data: params,
            headers: {
                'Cache-Control': 'no-cache',
                'content-Type': 'application/json',
            }
        })
            .then( res => dispatch(handler.SUCCESS('SEND', res)) )
            .catch( err => dispatch(handler.FAILURE('SEND', err)) )
    }
}