import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm from "react-hook-form";
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import Button from "../../Button";
import { Input } from "../../Form";
import { ModalContext } from "./modalContext";
import {editRefAction as editPromo, setPostBack} from '../../../actions';
import './PromoModal.scss';

function PromoModal({ editPromoName, setPostBack }) {
    const {
        isOpen,
        closeModal,
        promoID: id,
        promoName,
        changereg,
        changedep,
        changeaccudep
    } = useContext(ModalContext);

    const { register, handleSubmit } = useForm();

    const onSubmit = ({ changename, ...args }) => {
        editPromoName(changename, id);
        setPostBack(id, args);

        closeModal();
    };

    return (
        <Modal
            className="promo__modal"
            show={isOpen}
            onHide={closeModal}
        >
            <form
                className="promo__forms"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Modal.Body>
                    <Input
                        labelClassName="promo__label"
                        className={classNames(!promoName && 'd-none')}
                        label="Change promo name"
                        name="changename"
                        inputClassName="promo__input"
                        refer={register}
                        defaultValue={promoName}
                    />
                    <Input
                        labelClassName="promo__label"
                        className={classNames(promoName && 'd-none')}
                        label="Change Registration URL"
                        name="changereg"
                        inputClassName="promo__input"
                        refer={register}
                        defaultValue={changereg}
                    />
                    <Input
                        labelClassName="promo__label"
                        className={classNames(promoName && 'd-none')}
                        label="First deposit URL"
                        name="changedep"
                        inputClassName="promo__input"
                        refer={register}
                        defaultValue={changedep}
                    />
                    <Input
                        labelClassName="promo__label"
                        className={classNames(promoName && 'd-none')}
                        label="Qualification URL"
                        name="changeaccudep"
                        inputClassName="promo__input"
                        refer={register}
                        defaultValue={changeaccudep}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="button-default button-yellow button-sm"
                        onClick={closeModal}
                    >
                        Cancel
                    </div>
                    <Button
                        className="button-sm"
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

PromoModal.propTypes = {
    hash: PropTypes.string.isRequired,
    editPromoName: PropTypes.func.isRequired,
    setPostBack: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { hash }) => {
    return {
        editPromoName: (name, id) => {
            if (name.length) {
                dispatch(editPromo({
                    id,
                    hash,
                    name,
                    action: 'changename'
                }))
            }
        },
        setPostBack: (id, fields) => {
            for ( let item in fields  ) {
                fields[item].length &&
                dispatch(setPostBack({
                    id,
                    hash,
                    action: item,
                    url: fields[item]
                }));
            }
        }
    }
};


export default connect(
    null,
    mapDispatchToProps
)(PromoModal);