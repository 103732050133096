import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FakeCheckbox from '../../../Form/FakeCheckbox';
import Button from '../../../Button';
import {ReactComponent as GIco} from '../../../../assets/img/inner/general.svg';
import './OptionFilter.scss';


const options = [
    {
        name: 'General',
        option: 'date',
    },
    {
        name: 'By SubID',
        option: 'subid',
    },
    {
        name: 'By Uid',
        option: 'uid',
    },
    {
        name: 'By Players',
        option: 'players',
    },
];

const OptionFilter = memo(function Option({
    className,
    activeOption,
    setOption,
    setLink_ID,
    selected,
    setSelected,
}) {

    const handleClick = option => () => {
        if ( option !== 'uid' && !!_.find(selected, 'id' )) {
            setLink_ID([]);

            setSelected( _.filter(selected, e => !e.id) );
        }

        setOption(option);
    };

    return (
        <div className={`stats-check__wrapper ${className}`}>
            <div className="row">
                {options.map( item => (
                    <div
                        key={item.name}
                        className="col-6 col-md-3 col-lg-4 text-center"
                    >
                        <div className="stats-check__item"
                             onClick={handleClick(item.option)}
                        >
                            <FakeCheckbox small isActive={activeOption === item.option}>
                                <GIco />
                                <div className="d-flex align-items-center justify-content-center">
                                    { item.name }
                                </div>
                            </FakeCheckbox>
                        </div>
                    </div>
                ))}
                <div className="col-lg-8 stats-check__btn">
                    <Button>filter</Button>
                </div>
            </div>
        </div>
    );
});

OptionFilter.defaultProps = {
    className: null
};

OptionFilter.propTypes = {
    className: PropTypes.string,
    setOption: PropTypes.func.isRequired,
    activeOption: PropTypes.string.isRequired,
    setLink_ID: PropTypes.func.isRequired,
    selected: PropTypes.array,
    setSelected: PropTypes.func.isRequired
};

export default OptionFilter;
