import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { progressController } from '../../utils';
import {ReactComponent as PointIco} from '../../../../assets/img/icon3.svg';
import {ReactComponent as CurPointIco} from '../../../../assets/img/icon2.svg';
import {ReactComponent as ActivePointIco} from '../../../../assets/img/icon1.svg';
import './ProgressBar.scss';

const ProgressBar = ({ step }) => {

    const setImage = (num) => (
        ( step === num ? <CurPointIco /> : null ) ||
        ( step >= num ? <ActivePointIco /> : <PointIco /> )
    );

    const setClass = (num) => (
        step >= num ? 'step__text--active' : null
    );

    return (
        <div className="step mb-3">
            <div className="step__progress">
                <div
                    className="step__progress-value"
                    style={{width: progressController(step)}}
                />
            </div>
            <div className="step__item">
                <div className={`step__text ${setClass(1)}`}>
                    program
                </div>
                {setImage(1)}
            </div>
            <div className="step__item">
                <div className={`step__text ${setClass(2)}`}>
                    promo Type
                </div>
                {setImage(2)}
            </div>
            <div className="step__item">
                <div className={`step__text ${setClass(3)}`}>
                    promo
                </div>
                {setImage(3)}
            </div>
            <div className="step__item">
                <div className={`step__text ${setClass(4)}`}>
                    Refer to
                </div>
                {setImage(4)}
            </div>
            <div className="step__item">
                <div className={`step__text ${setClass(5)}`}>
                    LINK
                </div>
                {setImage(5)}
            </div>
        </div>
    )
};

ProgressBar.propTypes = {
    step: PropTypes.number.isRequired
};

export default memo(ProgressBar);