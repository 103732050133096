import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SIGN_OUT,
} from '../constants/actionTypes';

const initialState = {
    data: {},
    loading: false,
    hash: '',
    error: '',
};

const signInReducer = ( state = initialState, { type, payload } ) => {
    switch (type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
                hash: payload.data.hash,
                error: payload.data.error,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload.data.error
            };
        case SIGN_OUT:
            return {
                ...state,
                data: {},
                hash: ''
            };
        default:
            return state
    }
};

export default signInReducer;