import React from 'react';
import BannerSlider from '../../components/IndexComponent/BannerSlider';
import BrandsSlider from '../../components/IndexComponent/BrandsSlider';
import About from '../../components/IndexComponent/About';
import Commission from '../../components/IndexComponent/Commission';
import Testimonials from '../Testimonials/Testimonials';
import { News } from '../../components/News';
import Contacts from '../../components/IndexComponent/Contacts';

const Main = () => (
    <div className="main">
        <BannerSlider />
        <BrandsSlider />
        <About />
        <Commission />
        <Testimonials />
        <News className="bg_crown" />
        <Contacts />
    </div>
);

export default Main;
