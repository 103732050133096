import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import { Input } from '../../components/Form';
import Button from "../../components/Button";
import AuthError from '../../components/Auth/AuthError';
import  IsError from '../../components/IsError';
import './ForgotPassword.scss';

function ForgotPassword({ resetPassword, resetSuccess, resetError }) {
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = ({ email }) => {
        resetPassword({ email })
    };

    return (
        <div className="forgot-password">
            <div className="pt-5 pb-5 container">
                <div className="container-title">
                    forgot password
                </div>
                {!resetSuccess ?
                    <div className="mb-4 text forgot-password__text">
                        Enter your mail address <br/>
                        and we send you instructions <br/>
                        to sing in
                    </div> :
                    <div className="mb-4 text forgot-password__text">
                        Check your mailbox
                    </div>
                }
                {!resetSuccess ?
                    <form
                        className="forgot-password__form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Input
                            type="email"
                            inputClassName="forgot-password__input"
                            placeholder="Email"
                            name="email"
                            refer={register({
                                required: true,
                                pattern: /\S+@\S+\.\S+/
                            })}
                        />
                        <AuthError error={errors.email} />
                        <Button className="mt-3">SEND</Button>
                    </form>
                    : null
                }
                <IsError
                    className="mt-3"
                    errorText={resetError}
                />
            </div>
        </div>
    )
}

ForgotPassword.propTypes = {
    resetSuccess: PropTypes.bool.isRequired,
    resetError: PropTypes.string.isRequired,
    resetPassword: PropTypes.func.isRequired,
};

export default ForgotPassword;
