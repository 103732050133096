import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import './Calendar.scss';

function Calendar(props) {
    const {
        className,
        sets: {
            to,
            from,
            setTo,
            setFrom
        }
    } = props;

    const handleFrom = e => setFrom(e);
    const handleTo = e => setTo(e);

    return (
        <div className={classNames('InputFromTo calendar-filter', className)}>
            <div className="calendar-filter__from">
                <span className="text">FROM:</span>
                <DayPickerInput
                    value={from || '-'}
                    placeholder="From"
                    format="YYYY-MM-DD"
                    onDayChange={handleFrom}
                    dayPickerProps={{
                        modifiers: {
                            disabled: [{ after: to }],
                        },
                        selectedDays: [from, {from, to}]
                    }}
                />
            </div>
            <div className="calendar-filter__to">
                <span className="text">TO:</span>
                <DayPickerInput
                    value={to || '-'}
                    placeholder="To"
                    format="YYYY-MM-DD"
                    onDayChange={handleTo}
                    dayPickerProps={{
                        modifiers: {
                            disabled: [{ before: from }]
                        },
                        selectedDays: [from, {from, to}]
                    }}
                />
            </div>
        </div>
    )
}

Calendar.defaultProps = {
    className: null,
};

Calendar.propTypes = {
    className: PropTypes.string,
    sets: PropTypes.shape({
        from: PropTypes.object,
        to: PropTypes.object,
        setFrom: PropTypes.func,
        setTo: PropTypes.func
    }).isRequired
};

export default Calendar;