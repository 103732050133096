import React from 'react';
import PropTypes from 'prop-types';
import HeaderBalance from './HeaderBalance';
import BoardBalance from './BoardBalance';
import AsideBalance from './AsideBalance';
import './MoneyBalance.scss';

const MoneyBalance = ({
    mode,
    className,
    boardClassName,
    balance,
    cparate,
    refshare,
    today
}) => {

    if (mode === 0) {
        return (
            <AsideBalance
                moneyStatus={{
                    cparate,
                    refshare,
                    balance,
                    today
                }}
            />
        )
    }

    if ( mode === 1 ) {
        return (
            <BoardBalance
                boardClassName={boardClassName}
                moneyStatus={{
                    cparate,
                    refshare,
                    balance,
                    today
                }}
            />
        )
    }

    if ( mode === 3 ) {
        return (
            <HeaderBalance
                className={className}
                moneyStatus={{
                    cparate,
                    refshare,
                    balance,
                    today
                }}
            />
        )
    }
};

MoneyBalance.propTypes = {
    mode: PropTypes.number.isRequired,
    className: PropTypes.string,
    boardClassName: PropTypes.string,
    cparate: PropTypes.string.isRequired,
    refshare: PropTypes.string.isRequired,
    balance: PropTypes.string.isRequired,
    today: PropTypes.string.isRequired,
};



export default MoneyBalance;
