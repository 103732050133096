import axios from 'axios';
import { handler } from '../utils';
import { FILTER_STATS_COLUMNS } from '../constants/actionTypes';

const URL = 'https://web.lucky4winners.com/api/?type=stats';

export function getStats(filter) {
   return dispatch => {
       dispatch(handler.REQUEST('STATS'));
       axios({
           method: 'post',
           url: URL,
           data: filter,
           headers: {
               'Cache-Control': 'no-cache',
               'content-Type': 'application/json',
           }
       })
           .then( res => dispatch(handler.SUCCESS('STATS', res)) )
           .catch( err => dispatch(handler.FAILURE('STATS', err)) )
   }
}

export const filterStatsTable = hidden => ({
    type: FILTER_STATS_COLUMNS,
    payload: hidden
});