import React from 'react';
import PropTypes from 'prop-types';
import { InnerHeader } from '../../components/Header';
import { InnerMenu } from '../../components/Menu';
import Footer from '../../components/Footer';
import Figure from '../../components/Figure';
import './InnerLayout.scss';

const InnerLayout = ({children}) => {
    return (
        <div className="admin">
            <InnerHeader/>
            <main className="inner d-flex">
                <InnerMenu/>
                <div className="inner__content">
                    <Figure />
                    {children}
                </div>
            </main>
            <Footer/>
        </div>
    )
};

InnerLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default InnerLayout;
