import React, { useState } from 'react';
import Logo from '../Logo';
import { Collapse as MobileMenuWrap } from 'react-bootstrap';
import Toggler from '../Toggler';
import { DesktopMenu, MobileMemu } from '../../Menu';
import {AuthController} from '../../Auth';
import history from '../../../router/history';
import './OuterHeader.scss';

function OuterHeader() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);
    const handleToHome = () => {
        if ( isOpen === true ) {
            setIsOpen(false);
        }

        history.push('/');
    };

    return (
        <>
            <header className="row ml-0 mr-0 header">
                <Logo
                    className="col-6 col-xl-2 header-logo"
                    onClick={handleToHome}
                />
                <DesktopMenu
                    className="col-lg-6 d-none d-xl-flex header-nav"
                />
                <Toggler
                    className={'col-6 d-block d-xl-none header-toggle'}
                    clicked={isOpen}
                    onClick={toggleMenu}
                />
                <AuthController
                    className="
                    col-lg-4 d-none
                    d-xl-flex header-auth"
                />
            </header>
            <MobileMenuWrap
                className="mobile-menu"
                in={isOpen}
            >
                <div>
                    <MobileMemu closeMenu={toggleMenu} />
                </div>
            </MobileMenuWrap>
        </>
    );
}

export default OuterHeader;
