import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './HeaderBalance.scss';

const HeaderBalance = ({
    className,
    moneyStatus: {
        cparate,
        refshare,
        balance,
        today
    }
}) => (
    <div className={classNames('header-balance', className)}>
        <div className="text-small">
            rates: <span>{cparate}/{refshare}</span>
        </div>
        <div className="text-small">
            today: <span>{parseFloat(today).toFixed(2)}</span>
        </div>
        <div className="text-small">
            balance: <span>{parseFloat(balance).toFixed(2)}</span>
        </div>
    </div>
);

HeaderBalance.propTypes = {
    moneyStatus: PropTypes.objectOf(PropTypes.string).isRequired
};

export default HeaderBalance;
