import React, {memo} from 'react';
import PropTypes from 'prop-types';
import './CustomLegend.scss';

const CustomLegend = memo(({fValue, sValue}) => (
    <div className="custom-legend">
        <div className="custom-legend__item text">
            {fValue.replace(/_/g, ' ')} <span></span>
        </div>
        <div className="custom-legend__item text">
            {sValue.replace(/_/g, ' ')} <span></span>
        </div>
    </div>
));

CustomLegend.propTypes = {
    fValue: PropTypes.string,
    sValue: PropTypes.string,
};

export default CustomLegend;
