import {
    GET_LINKID_LOADING,
    GET_LINKID_SUCCESS,
    GET_LINKID_ERROR,
    EDIT_REF_LOADING,
    EDIT_REF_SUCCESS,
    //EDIT_REF_ERROR,
} from "../../constants/actionTypes";

const initialState = {
    data: {},
    loading: false,
    error: null,
};

export default ( state = initialState, { type, payload } ) => {
    switch (type) {
        case GET_LINKID_LOADING:
        case EDIT_REF_LOADING:
            return {
                ...state,
                error: null,
                loading: true
            };
        case GET_LINKID_SUCCESS:
            return {
                ...state,
                data: payload.data,
                loading: false
            };
        case EDIT_REF_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case GET_LINKID_ERROR:
            return {
                ...state,
                error: 'referral link error',
                loading: false
            };
        default:
            return state;
    }
};
