import {
    PAYMENT_REQUEST,
    PAYMENT_SUCCESS,
    PAYMENT_FAILURE
} from '../constants/actionTypes';

const initialState = {
    data: [],
    loading: true,
    error: null,
    payInfo: '',
};

export default ( state = initialState, { type, payload } ) => {
    switch (type) {
        case PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data.data,
                payInfo: payload.data.paymentinfo,
                error: null
            };
        case PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                payInfo: '',
                error: payload.error
            };
        default:
            return state
    }

}