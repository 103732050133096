export const timeCreated = () => {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth()+1;
    const yyyy = today.getFullYear();
    const H = today.getHours();
    const M = today.getMinutes();

    if ( dd < 10 ) { dd='0'+dd }
    if ( mm < 10 ) { mm='0'+mm }

    return {
        date: `${dd}.${mm}.${yyyy}`,
        time: `${H}:${M}`
    };
};