import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm from 'react-hook-form';
import Input from '../../../Form/Input';
import CheckBox from '../../../Form/CheckBox';
import Button from '../../../Button';
import AuthError from '../../AuthError';
import { signUp } from '../../../../actions';
import './SignUpForm.scss';

function SignUpForm({signUp, isError}) {
    const { register, handleSubmit, watch, errors } = useForm();

    const onSubmit = ({ re_password, ...args }) => {
        signUp(args);
    };

    return (
        <form
            className="row reg-modal__form"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            <div className="col-md-6">
                <Input
                    label="Log-in*"
                    type="text"
                    name="login"
                    placeholder="Log-in"
                    refer={register({
                        required: true,
                        minLength: 6,
                        pattern: /^[A-z0-9]+$/
                    })}
                />
                <AuthError error={errors.login} />
                <Input
                    label="Email*"
                    type="text"
                    name="email"
                    placeholder="Email"
                    refer={register({
                        required: true,
                        pattern: /\S+@\S+\.\S+/

                    })}
                />
                <AuthError error={errors.email} />
                <Input
                    label="Password*"
                    type="password"
                    name="password"
                    placeholder="Password"
                    refer={register({
                        required: true,
                        pattern: /^(?=.*[a-z])(?=.*[0-9])/,
                        minLength: 6
                    })}
                    autoComplete="off"
                />
                <AuthError error={errors.password} />
            </div>
            <div className="col-md-6">
                <Input
                    label="Skype*"
                    type="text"
                    name="skype"
                    placeholder="Skype"
                    refer={register({
                        required: true,
                        minLength: 6
                    })}
                />
                <AuthError error={errors.skype} />
                <Input
                    label="Site*"
                    type="text"
                    name="site"
                    placeholder="Site"
                    refer={register({
                        required: true,
                    })}
                />
                <AuthError error={errors.site} />
                <Input
                    label="Password repeat*"
                    type="password"
                    name="re_password"
                    placeholder="Password repeat"
                    refer={register({
                        required: true,
                        validate: (val ) => val === watch('password')
                    })}
                    autoComplete="off"
                />
                <AuthError error={errors.re_password} />

            </div>
            <div className="col-12 col-sm-6 mb-3 mt-3 reg-modal__field-checkbox">
                <CheckBox
                    name="terms"
                    reff={register({ required: true })}
                />
                <span>
                        I agree and accept Terms and Conditions*
                    </span>
            </div>
            <div className="col-12 col-sm-6 mb-3 mt-3 reg-modal__field-checkbox">
                <CheckBox
                    name="policy"
                    reff={register({ required: true })}
                />
                <span>
                        I agree with Data Protection Policy*
                    </span>
            </div>
            <div className="col-12 text-center auth-error">{isError}</div>
            <Button className="reg-modal__submit">
                register
            </Button>
        </form>
    );
}

SignUpForm.defaultProps = {
    isError: null
};

SignUpForm.propTypes = {
    isError: PropTypes.string,
    signUp: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    signUp: (args) => {

        const url = new URL(window.location.href),
            refid = url.searchParams.get('refid') || '21232f297a57a5a743894a0e4a801fc3';

        return dispatch(signUp({
            ...args,
            refid
        }))
    }
});

export default connect(
    null,
    mapDispatchToProps
)(SignUpForm);
