import React from 'react';
import {string} from 'prop-types';
import classNames from 'classnames';
import './Flag.scss'

const Flag = ({lang, className, style}) => (
    <span className={classNames(`flag flag_${lang}`, className)} style={style} />
);

Flag.propTypes = {
    lang: string.isRequired,
    className: string//.isRequired
};

export default Flag;