import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {ProgressBar, PromoNavigation, StepTitle} from './PromoHandles';
import CurrentStep from './CurrentStep';
import { isDisabled } from './utils';
import { promoPages, promoTypes } from '../../constants';
import './Tracking.scss';

class Tracking extends Component {
    state = this.initialState;

    get initialState() {
        return {
            stepId: 1,
            promo: {
                brand: '',
                type: '',
                layout: '',
                isWrapped: {},
                selectedReferral: {},
            }
        }
    }

    static propTypes = {
        hash: PropTypes.string.isRequired,
    };

    resetPromo = () => {
        this.setState(this.initialState);
    };

    setPromo = (e, promoStep) => {
        const { promo } = this.state;
        promo[promoStep] = e;

        this.setState({promo});
    };

    prevStep = () => {
        const { stepId, promo: { type } } = this.state;

        switch (stepId) {
            case promoPages.type:
                this.setPromo('', 'type');
                this.setState({stepId: promoPages.brand});
                break;
            case promoPages.layout:
                this.setPromo('', 'layout');
                this.setState({stepId: promoPages.type});
                break;
            default:
                this.setPromo('', 'delivery');
                type === promoTypes.direct ?
                    this.setState({stepId: promoPages.type}):
                    this.setState({stepId: promoPages.layout});
        }
    };

    nextStep = () => {
        const { stepId, promo: { type } } = this.state;

        switch (stepId) {
            case promoPages.brand:
                this.setState({ stepId: promoPages.type });
                break;
            case promoPages.type:
                type === promoTypes.direct ?
                    this.setState({ stepId: promoPages.delivery }):
                    this.setState({ stepId: promoPages.layout });
                break;
            case promoPages.layout:
                type === promoTypes.landings ?
                    this.setState({ stepId: promoPages.final }):
                    this.setState({ stepId: promoPages.delivery });
                break;
            default:
                this.setState({ stepId: promoPages.final });
                break;
        }
    };

    render() {
        const {
            stepId,
            promo: {
                type,
                isWrapped,
                selectedReferral
            },
        } = this.state;

        return (
            <>
                <ProgressBar
                    step={stepId}
                />
                <StepTitle
                    step={stepId}
                    type={type}
                    disabled={isDisabled(this.state)}
                    prevStep={this.prevStep}
                    nextStep={this.nextStep}
                />
                <CurrentStep
                    hash={this.props.hash}
                    setPromo={this.setPromo}
                    isWrapped={isWrapped}
                    referralInfo={selectedReferral}
                    resetPromo={this.resetPromo}
                    {...this.state}
                />
                <PromoNavigation
                    step={stepId}
                    disabled={isDisabled(this.state)}
                    prevStep={this.prevStep}
                    nextStep={this.nextStep}
                />
            </>
        )
    }
}

export default Tracking;