import {
    POST_BACK_REQUEST,
    POST_BACK_SUCCESS,
    POST_BACK_FAILURE
} from "../../constants/actionTypes";

const initialState = {
    changed: false,
    loading: false,
    error: false
};

export default ( state = initialState, { type, payload } ) => {
    switch (type) {
        case POST_BACK_REQUEST:
            return {
                ...state,
                loading: true
            };
        case POST_BACK_SUCCESS:
            return {
                ...state,
                changed: !!payload.data.status,
                error: !!payload.data.error,
                loading: false
            };
        case POST_BACK_FAILURE:
            return {
                ...state,
                loading: false,
                error: !!payload.data.error,
            };
        default:
            return state;
    }
}