import { compose } from 'redux';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { getStats } from '../../actions/stats';

export default compose(
    connect(
        ({ stats: { data, total }, logInfo: { hash } }) => ({
            data, total, hash
        }),
        { getStats })
)(Dashboard)
