import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import {
    saveStorage,
    persistedState,
} from './utils/storageController';
import './assets/styles/index.scss';
import App from './App';

const store = createStore(
    reducer,
    persistedState(),
    compose(
        applyMiddleware(thunk),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

let currentValue;
function handleChange() {
    let previousValue = currentValue;
    currentValue = store.getState();

    if ( previousValue && ( previousValue.logInfo.hash !== currentValue.logInfo.hash ) ) {
        saveStorage(store.getState());
    }
}

store.subscribe(() => handleChange() );

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('app')
);
