import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as ArrowIco} from '../../assets/img/down.svg';
import './ArrowDropdown.scss';

function ArrowDropdown({children}) {
    const [ rotate, setRotate ] = useState(0);

    const rotateHandler = () => {
        rotate === 0 ? setRotate(180): setRotate(0)
    };

    return (
        <div className="arrow-wrap" onClick={rotateHandler}>
            {children}
            <ArrowIco style={{transform: `rotate(${rotate}deg)`}} />
        </div>
    );
}

ArrowDropdown.propTypes = {
    children: PropTypes.node.isRequired
};

export default ArrowDropdown;