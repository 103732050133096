import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CustomChart from '../../CustomChart';
import CalendarButtons from '../../Calendar/CalendarButtons';
import { StatsContext } from '../../../context';
import './DashboardChart.scss';

function DashboardChart({ data }){
    const [ ngr, setNgr ] = useState([]);

    const {
        date: {
            setFrom,
            setTo,
            rangeHandler,
            setRangeHandler
        },
        setPage,
    } = useContext(StatsContext);

    useEffect(() => {
        const ngr_list = data.map( obj =>
            ({
                name: Object.values(_.pick(obj, ['Date'])).join(),
                line: +Object.values(_.pick(obj, ['NGR'])) >= 0 ?
                    +Object.values(_.pick(obj, ['NGR'])) : 0
            })).reverse();

        setNgr(ngr_list)
    }, [data] );

    return (
        <div className="dashboard-chart">
            <CalendarButtons
                sets={{
                    setFrom,
                    setTo,
                    rangeHandler,
                    setRangeHandler,
                    setPage,
                }}
            />
            <CustomChart data={ngr} />
        </div>
    );
}

DashboardChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
};

export default DashboardChart;