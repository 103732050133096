import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '../../../Form';
import './UserCongrats.scss';

const UserCongrats = ({ login: username, brands, className }) => (
    <div className={`header-congrats ${className}`}>
        <div className="text-small header-congrats__name">
            HELLO, <span>{username}</span>
        </div>
        <Select
            className="yellow"
            options={brands}
            value={{ value: 'kingBilly', label: 'King Billy' }}
        />
    </div>
);

UserCongrats.defaultProps = {
    className: null,
};

UserCongrats.propTypes = {
    className: PropTypes.string,
    login: PropTypes.string.isRequired,
    brands: PropTypes.arrayOf(PropTypes.object)
};

export default UserCongrats;
