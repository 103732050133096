import React from 'react';
import PropTypes from 'prop-types';
import mail_icon from "../../../../assets/img/mail_icon.svg";
import skype_icon from "../../../../assets/img/skype_icon.svg";
import './ContactProps.scss';

const contactProp = [
    {
        name: 'DARIA',
        mail: 'daria@kingsclub.io',
        skype: 'live:dariiazheleznova13'
    },
    // {
    //     name: 'Max',
    //     mail: 'max@kingsclub.io',
    //     skype: 'live:m.stepasyuk'
    // }
];

const ContactProps = ({ className }) => {
    return (
        <div className={`contact__props ${className}`}>
            {contactProp.map(admin => (
                <div
                    key={admin.name}
                    className="contact__item"
                >
                    <div className="text-head contact__name">
                        {admin.name}
                    </div>
                    <div className="contact__mail">
                        <img
                            src={mail_icon}
                            alt=""
                        />
                        <a
                            className="text"
                            href={`mailto:${admin.mail}`}
                        >
                            {admin.mail}
                        </a>
                    </div>
                    <div className="contact__skype">
                        <img
                            src={skype_icon}
                            alt=""
                        />
                        <a
                            className="text"
                            href={`skype:${admin.skype}`}
                        >
                            {admin.skype}
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
};

ContactProps.propTypes = {
    className: PropTypes.string.isRequired
};

export default ContactProps;
