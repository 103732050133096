import React from 'react';
import PropTypes from 'prop-types';
import { TODAY, WEEK, MONTH } from '../types';
import { handleSelectMonth, handleSelectToday, handleSelectWeek } from '../../../utils/calendar';
import './CalendarButtons.scss';

function CalendarButtons({ sets, className }) {

    const {
        setFrom,
        setTo,
        rangeHandler,
        setRangeHandler,
        setPage,
    } = sets;


    const dateRangeHandler = d => () => {

        switch (d) {
            case TODAY:
                handleSelectToday(setFrom, setTo);
                break;
            case WEEK:
                handleSelectWeek(setFrom, setTo);
                break;
            case MONTH:
                handleSelectMonth(setFrom, setTo);
                break;
            default:
                new Error();
        }

        if (setPage) {
            setPage(1);
            setRangeHandler(!rangeHandler);
        }
    };

    return (
        <div className={`calendar-filter__select-buttons ${className}`}>
            <div onClick={dateRangeHandler(TODAY)}>today!</div>
            <div onClick={dateRangeHandler(WEEK)}>week!</div>
            <div onClick={dateRangeHandler(MONTH)}>month</div>
        </div>
    );
}

CalendarButtons.defaultProps = {
    className: null,
    setPage: null
};

CalendarButtons.propTypes = {
    className: PropTypes.string,
    sets: PropTypes.shape({
        setFrom: PropTypes.func.isRequired,
        setTo: PropTypes.func.isRequired,
        rangeHandler: PropTypes.bool,
        setRangeHandler: PropTypes.func,
        setPage: PropTypes.func
    }).isRequired
};

export default CalendarButtons;
