import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {AuthModalContext} from '../../../context';

function AuthModalState({children}) {
    const [isOpenLogIn, setIsOpenLogIn] = useState(false);
    const [isOpenSignUp, setIsOpenSignUp] = useState(false);

    const handleLogInModal = () => {
        if ( isOpenLogIn ) {
            setIsOpenLogIn(false);
        } else {
            setIsOpenLogIn(true)
        }
    };
    const handleSignUpModal = () => {
        if (isOpenSignUp) {
            setIsOpenSignUp(false);
        } else {
            setIsOpenSignUp(true)
        }
    };

    return (
        <AuthModalContext.Provider value={{
            isOpenLogIn,
            isOpenSignUp,
            handleLogInModal,
            handleSignUpModal,
        }}>
            {children}
        </AuthModalContext.Provider>
    )
}

AuthModalState.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthModalState;