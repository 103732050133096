import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReferrFilter from './ReferrFilter';
import { Select } from '../../../Form';
import {
    availableLangs,
    availableForms,
    availableTypes,
    promoTypes,
} from '../../../../constants';

const LayoutFilter = props => {

    const {
        all,
        reff,
        option,
        refType,
        promoType,
        setRefType,
        filterAction,
        setDirectLang,
    } = props;

    const [ selectedLang, setSelectedLang ] = useState(availableLangs[0]);
    const [ selectedType, setSelectedType ] = useState(availableTypes[0]);
    const [ selectedForm, setSelectedForm ] = useState(availableForms[0]);

    useEffect(() => {
        setDirectLang(selectedLang.value);

        if (promoType !== promoTypes.direct) {
            filterAction({
                option,
                type: selectedType.value,
                position: selectedForm.value,
                lang: selectedLang.value
            });
        }
        // eslint-disable-next-line
    }, [selectedLang, selectedType, selectedForm]);

    return (
        <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <Select
                    name="languages"
                    value={[selectedLang]}
                    options={availableLangs}
                    placeholder="Select language"
                    setValue={setSelectedLang}
                    onChange
                />
            </div>
            <div className={all ? 'col-12 col-sm-6 col-md-4 col-lg-3' : 'd-none'}>
                <Select
                    name="type"
                    value={[selectedType]}
                    options={availableTypes}
                    placeholder="Select type"
                    setValue={setSelectedType}
                    onChange
                />
            </div>
            <div className={all ? 'col-12 col-sm-6 col-md-4 col-lg-3' : 'd-none'}>
                <Select
                    name="form"
                    value={[selectedForm]}
                    options={availableForms}
                    placeholder="Select form"
                    setValue={setSelectedForm}
                    onChange
                />
            </div>
            {reff && promoType !== promoTypes.direct ?
                <ReferrFilter
                    className="col-12 col-sm-6 col-md-4 col-lg-3"
                    setRefType={setRefType}
                    refType={refType}
                /> :
                null
            }
        </div>
    );
};

LayoutFilter.defaultProps = {
    all: false,
    reff: false,
    promoType: null,
    setDirectLang: () => {}
};

LayoutFilter.propTypes = {
    all: PropTypes.bool,
    reff: PropTypes.bool,
    promoType: PropTypes.string,
    setRefType: PropTypes.func,
    refType: PropTypes.string,
    directLang: PropTypes.func,
    setDirectLang: PropTypes.func,
    option: PropTypes.string.isRequired,
    filterAction: PropTypes.func.isRequired,
};

export default LayoutFilter;
