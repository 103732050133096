const handler = {
    REQUEST: type => ({
        type: type + '_REQUEST'
    }),
    SUCCESS: (type, res) => ({
        type: type + '_SUCCESS',
        payload: res
    }),
    FAILURE: (type, err) => ({
        type: type + '_FAILURE',
        payload: err
    })
};

export {
    handler
};