import React from 'react';
import PropTypes from 'prop-types';
import './AuthTitle.scss';

const AuthTitle = ({ text, onClick, logo }) => {
    return (
        <div className="text-center text-head auth-title">
            { logo ? <img className="auth-title__logo" src={logo} alt="" /> : null }
            {text}
            <div
                className="auth-title__close"
                onClick={onClick}
            />
        </div>
    );
};

AuthTitle.defaultProps = {
    logo: null
};

AuthTitle.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    logo: PropTypes.string,
};

export default AuthTitle;
