import { combineReducers } from 'redux';
import { default as testimonials } from './testimonailsReducre';
import { default as logInfo } from './signInReducer'
import { default as regInfo } from './signUpReducer';
import { default as stats } from './statsReducer';
import { default as news } from './newsReducer';
import {
    layoutsState,
    referralState,
    deliveryState,
    promosList,
    postBackReducer as postBack,
} from './promosReducer';
import { default as contactStatus } from './contactReducer';
import { default as paymentsList } from './paymentsReducer';
import { default as passStatus } from './passwordReducer';

export default combineReducers({
    logInfo,
    regInfo,
    passStatus,
    testimonials,
    postBack,
    news,
    stats,
    layoutsState,
    deliveryState,
    referralState,
    promosList,
    contactStatus,
    paymentsList
});