import React from 'react';
import PropTypes from 'prop-types';
import "./BrandDescription.scss";

function BrandDescription() {
    return (
        <div className="row description">
            <div className="col-12 col-md-6 description__advantages">
                <div className="description__advantages-title text-head">
                    Why promote KingBilly
                </div>
                <ul className="description__advantages-list text">
                    <li>
                        Games:
                        Live dealer, slots, jackpots,
                        table games, bitcoin games
                    </li>
                    <li>
                        Version:
                        Desktop and mobile
                    </li>
                    <li>
                        Currencies:
                        CAD, EUR, AUD, NOK, RUB, USD,
                        ZAR, BTC, Bitcoin Cash, ETH, LTC, NZD.
                    </li>
                    <li>
                        Languages:
                        English, Finnish, German,
                        Norwegian, and Russian
                    </li>
                    <li>
                        License:
                        Curacao
                    </li>
                    <li className="mb-0">
                        Deposit Method:
                        EcoPayz, MasterCard, Neteller, PaysafeCard, instaDebit,
                        Visa, Neosurf, QIWI, Skrill, Bitcoin, Yandex Money,
                        iDebit, Litecoin, Zimpler, Ethereum, Bitcoin Cash, Interac,
                        Rapid Transfer, Alfa Click, WebMoney, PurplePay, Euroset,
                        Svyazno, Promsvyazbank, and CoinsPaid
                    </li>
                </ul>
            </div>
            <div className="col-12 col-md-6 description__advantages">
                <div className="description__advantages-title text-head">
                </div>
                <ul className="description__advantages-list text">
                    <li className="">
                        Game providers:
                        Microgaming, NetEnt, Evolution Gaming,
                        Play‘n GO, iSoftBet, Yggdrasil
                        Gaming, Rabcat, BGAMING, Ezugi, Amatic
                        Industries, Endorphina, GameArt, Booming
                        Games, Habanero, Pragmatic Play, Spinomenal,
                        Quickfire, Quickspin, VIVO Gaming, Booongo Gaming,
                        Betsoft, Felix Gaming, Nolimit City, and Playson
                    </li>
                    <li>
                        Target market:
                        Europe, Canada, Australia and New Zealand
                    </li>
                    <li>
                        Withdrawal Method:
                        MasterCard, Neteller, instaDebit, Visa, Bank transfer,
                        EcoPayz, QIWI, Skrill, Bitcoin, Litecoin, Yandex Money,
                        iDebit, Ethereum, Bitcoin Cash, Rapid Transfer, and WebMoney
                    </li>
                    <li className="mb-0">Support: Live Chat 24/7.</li>
                </ul>
            </div>
        </div>
    )
}

BrandDescription.propTypes = {
    activeTab: PropTypes.number,
};

BrandDescription.defaultProps = {
    activeTab: 1
};

export default BrandDescription;
