import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { filterStatsTable as tableFilter } from '../../../../actions/stats';
import SaveExcel from '../../SaveExcel';
import './TableFilter.scss';

const TableFilter = ({tableFilter, options, data}) => {

    const handleChange = val => tableFilter( val.map( i => i.value ) );

    return (
        <div className="t-filter row">
            <div className="col-12 col-md-4">
                <div className="text">Field</div>
                <ReactMultiSelectCheckboxes
                    options={[...options.map( (i) => ({ label: i.replace(/_/g, ' '), value: i }) )]}
                    onChange={handleChange}
                    placeholderButtonLabel="Select..."
                    getDropdownButtonLabel={({ placeholderButtonLabel, value }) => value="Select"}
                    classNamePrefix="t-filter"
                    hideSearch
                    //menuIsOpen
                />
            </div>
            <div className="col-12 col-md-8">
                <SaveExcel />
            </div>
        </div>
    );
};

TableFilter.propTypes = {
    tableFilter: PropTypes.func.isRequired,
    option: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.object)
};

export default connect(null, { tableFilter })(TableFilter);
