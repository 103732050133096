import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer'
import history from '../../router/history';
import { logOut } from '../../actions';

function Idle({ isLogged, children, logOut }) {
    const [ timeout ] = useState(1800000);
    const [ isTimedOut, setIsTimedOut ] = useState(false);
    let idleTimer = null;

    const onAction = () => {
        setIsTimedOut(false);
    };

    const onIdle = () => {
        console.log('Idle user');

        isTimedOut ? isIdle() : isNotIdle();

    };

    const isIdle = () => {
        logOut();

        history.push('/');
    };

    const isNotIdle = () => {
        idleTimer.reset();
        setIsTimedOut(true)
    };

    return(
        <>
            <IdleTimer
                ref={ref => { idleTimer = ref }}
                element={document}
                onIdle={isLogged ? onIdle: undefined}
                onAction={isLogged ? onAction: undefined}
                debounce={250}
                timeout={timeout}
            />
            <>
                {children}
            </>
        </>
    )
}

Idle.propTypes = {
    isLogged: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default connect(
    ( ({ logInfo: { hash: isLogged } }) => ({ isLogged }) ),
    { logOut }
)(Idle);