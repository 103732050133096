import React, { useContext } from 'react';
import {string} from 'prop-types';
import { AuthModalContext } from '../../../../context';
import './UnLogged.scss';

const UnLogged = () => {
    const {
        handleLogInModal,
        handleSignUpModal,
    } = useContext(AuthModalContext);

    return (
        <>
            <button
                className="header-auth__btn"
                onClick={() => handleSignUpModal()}
            >
                sign up
            </button>
            <button
                className="header-auth__btn"
                onClick={() => handleLogInModal()}
            >
                log in
            </button>
        </>
    )
};

UnLogged.propTypes = {
    className: string
};

export default UnLogged;