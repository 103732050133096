import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import { Input } from '../../../../Form';
import Button from '../../../../Button';
import { setPostBack } from '../../../../../actions'
import './PostBackForm.scss';

function PostBackForm({ setPostBack }) {
    const { register, handleSubmit } = useForm();

    const onSubmit = (fields) => setPostBack(fields);

    return (
        <form
            className="postBack__form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Input
                inputClassName="postBack__input"
                name="changereg"
                placeholder="Registration URL"
                refer={register}
            />
            <Input
                inputClassName="postBack__input"
                name="changedep"
                placeholder="First deposit URL"
                refer={register}
            />
            <Input
                inputClassName="postBack__input"
                name="changeaccudep"
                placeholder="Qualification URL"
                refer={register}
            />
            <Button className="postBack__send">
                save
            </Button>
        </form>
    );
}

PostBackForm.propTypes = {
    id: PropTypes.number,
    hash: PropTypes.string.isRequired,
    setPostBack: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    setPostBack: fields => {
        for ( let item in fields  ) {
            fields[item].length &&
            dispatch(setPostBack({
                ...ownProps,
                action: item,
                url: fields[item]
            }));
        }
    }
});

export default connect(
    null,
    mapDispatchToProps,
)(PostBackForm);