import React from 'react';
import PropTypes from 'prop-types';
import Board from "../../components/Board";
import Content from "../../components/Content";
import StatsFilter from "../../components/Stats/StatsFilter";
import StatsGraphic from "../../components/Stats/StatsGraphic";
import StatsTable from "../../components/Stats/StatsTable";
import StatsState from '../../components/Stats/StatsState';
import Error from '../../components/Stats/Error';
import ShortPreloader from "../../components/ShortPreloader";

const Stats = (props) => {

    const {
        stats: {
            offers,
            data,
            total,
            hiddenCols,
            error,
            loading
        },
        hash,
        getStats,
        location: {
            promoId
        }
    } = props;

    return (
        <>
            <div className="stats">
                <StatsState
                    hash={hash}
                    getStats={getStats}
                    promoId={promoId}
                >
                    <Board title="stats">
                        <StatsFilter
                            offers={offers}
                        />
                    </Board>
                    {loading ? <ShortPreloader /> :
                        error ? <Error error={error} /> :
                        <Content>
                        <StatsGraphic data={data}/>
                            <StatsTable
                                options={Object.keys(data[0])}
                                stats={data}
                                total={total}
                                hiddenCols={hiddenCols}
                            />
                    </Content>
                     }
                </StatsState>
            </div>
        </>
    )
};

Stats.propTypes = {
    stats: PropTypes.object,
    hash: PropTypes.string.isRequired,
    getStats: PropTypes.func,
    location: PropTypes.shape({
        promoId: PropTypes.object
    })
};

export default Stats;
