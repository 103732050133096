const graphicOptions = [
    'Host',
    'Clicks',
    'Regs',
    'FTD',
    'Conversion',
    'Players',
    'Deposits',
    'Withdraws_EUR',
    'CP_EUR',
    'NGR'
];

const mediaOptions = ( option, offers ) => {
    switch (option) {
        case 'uid':
            return [
                ...offers.map(offer => ({
                    value: offer.id,
                    label: offer.name
                })),
                ...offers.map(offer => ({
                    value: offer.linkid[0],
                    label: offer.linkid[0],
                    id: true
                }))
            ];
        default:
            return offers.map(offer => ({
                value: offer.id,
                label: offer.name
            }))
    }
};

export {
    graphicOptions,
    mediaOptions
};