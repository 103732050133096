import React from 'react';
import './PaymentTable.scss';
import ReactTable from 'react-table';

const PaymentTable = ({ data }) => {

    const columns = [
        {
            Header: 'Date',
            accessor: 'Date'
        },
        {
            Header: 'Description',
            accessor: 'Description'
        },
        {
            Header: 'Payment',
            accessor: 'Payment'
        },
    ];

    return (
        <div className="promo-list__wrap s-table">
            <ReactTable
                data={data}
                columns={columns}
                showPagination={false}
                pageSize={1}
            />
        </div>
    );
};

export default PaymentTable;
