import React from 'react';
import PropTypes from 'prop-types';
import './AuthFooter.scss';
import {Link} from "react-router-dom";

const AuthFooter = ({ closeModal, switchLogRegModal, text }) => {
    return (
        <div className="auth-footer">
            <div className="text-small auth-footer__forgot">
                <Link
                    to="/forgot-password"
                    onClick={closeModal}
                >
                    Forgot your password?
                </Link>
            </div>
            <div className="text auth-footer__join">
                {text}
                <button
                    className="auth-footer__swith"
                    onClick={switchLogRegModal}
                >
                    { text.includes('AL') ? 'sign-in' : 'join now' }
                </button>
            </div>
        </div>
    );
};

AuthFooter.defaultProps = {
    closeModal: null,
};

AuthFooter.propTypes = {
    closeModal: PropTypes.func,
    text: PropTypes.string.isRequired,
    switchLogRegModal: PropTypes.func.isRequired,
};

export default AuthFooter;
