import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import ShortPreloader from '../../ShortPreloader';
import LayoutBoxItem from '../PromoLayout/LayoutBoxItem';
import { deliveryAction as getDeliveryList } from '../../../actions/trackLinks';
import { promoTypes, directLink } from '../../../constants/promo';
import './PromoDelivery.scss';

function PromoDelivery(props) {
    const {
        hash, loading, data, promoType, refType,
        setPromo, getDeliveryList, directLang
    } = props;

    const [ active, setActive ] = useState('');

    useEffect(() => {
        if ( promoType !== promoTypes.direct ) {
            getDeliveryList({ hash, option: promoTypes.landings });
        }
        // eslint-disable-next-line
    }, []);

    const handleClick = layout => () => {
        setActive(layout.name);
        setPromo(layout.name, 'delivery');

        if ( refType === promoTypes.landings ) {
            return setPromo({
                name: layout.name,
                domain: layout.domain,
                type: promoTypes.landings
            }, 'selectedReferral');
        }

        setPromo({
            lang: layout.lang,
            type: layout.type
        }, 'selectedReferral');
    };

    if (loading) {
        return <ShortPreloader/>;
    }

    const directByLang = directLang.length >= 1 ? directLink.filter(i => i.lang === directLang) : directLink;
    const referralData = refType === promoTypes.landings ? data : directByLang;

    return (
        <div className="row layout">
            {referralData.map((layout, id) => (
                <LayoutBoxItem
                    key={id}
                    list={layout}
                    active={active}
                    onClick={handleClick}
                />
            ))}
        </div>
    )
}

PromoDelivery.propTypes = {
    hash: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    promoType: PropTypes.string.isRequired,
    directLang: PropTypes.string.isRequired,
    refType: PropTypes.string.isRequired,
    setPromo: PropTypes.func.isRequired,
    getDeliveryList: PropTypes.func.isRequired
};

export default connect(
    ({ deliveryState: { loading, data } }) => ({ loading, data }),
    { getDeliveryList }
)(PromoDelivery);
