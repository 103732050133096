import React from 'react';
import PropTypes from 'prop-types';
import Tracking from '../../components/Tracking';
import Board from '../../components/Board';
import Content from '../../components/Content';
import './TrackingList.scss';

const TrackingList = ({ hash }) => {
    return (
        <div className="tracking">
            <Board
                title="LPs & Banners"
                className="pb-0"
            />
            <Content className="pt-0 promo">
                <Tracking hash={hash} />
            </Content>
        </div>
    );
};

TrackingList.propTypes = {
    hash: PropTypes.string.isRequired
};

export default TrackingList;
