import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ShortPreloader from "../ShortPreloader";
import { ModalContext } from '../../components/PromoList/PromoModal';
import { PreviewContext } from '../../components/PromoList/PreviewModal';
import Clipboard from '../Clipboard';
import Button from '../Button';
import { getClipboardText } from './utils';
import {ReactComponent as EyeIco} from '../../assets/img/preview.svg';
import './PromoList.scss';

function PromoList(props) {
    const {
        isPromos,
        archivePromo,
        relivePromo,
        loading, data
    } = props;

    const {
        openModal,
        setPromoID,
        setPromoName,
        setChangereg,
        setChangedep,
        setChangeaccudep,
    } = useContext(ModalContext);

    const {
        openModal: openPreview,
        setPreview
    } = useContext(PreviewContext);

    const handleOpenModal = (id, name, ...args) => () => {
        setPromoID(id);
        setPromoName(name);
        setChangereg(args[0]);
        setChangedep(args[1]);
        setChangeaccudep(args[2]);

        openModal();
    };

    const showPreview = ( preview ) => () => {
        setPreview(preview);

        openPreview()
    };

    const columns = [
        {
            Header: 'UID',
            accessor: 'PromoCode',
        },
        {
            Header: 'Brand',
            Cell: props => <span className='number'>King Billy</span>,
        },
        {
            Header: 'Name',
            accessor: 'Name',
        },
        {
            Header: 'Type',
            accessor: 'Type',
        },
        {
            Header: 'Preview',
            Cell: ({ original }) => (
                <div className="preview__button" onClick={showPreview(original)}>
                    <EyeIco />
                </div>
            ),
        },
        {
            Header: 'Tools',
            Cell: ({ original }) => {
                return (
                    <div className="promo-list__btns">
                        <Clipboard
                            text={getClipboardText(original)}
                            className="ml-1 button-default button-sm"
                            clip="link"
                            clipped="copy"
                        />
                        <Link
                            to={{
                                pathname: '/stats',
                                promoId: {
                                    value: original.id,
                                    label: original.Name
                                }
                            }}
                            className="ml-1 button-default button-yellow button-sm"
                        >
                            stats
                        </Link>
                        <Button
                            className={classNames('ml-1 button-sm', !isPromos && 'd-none')}
                            onClick={() => archivePromo(original.id)}
                        >
                            archive
                        </Button>
                        <Button
                            className={classNames('ml-1 mr-1 button-grey button-sm', !isPromos && 'd-none')}
                            onClick={handleOpenModal(original.id, original.Name)}
                        >
                            edit
                        </Button>
                        <Button
                            className={classNames('button-grey button-sm', !isPromos && 'd-none')}
                            onClick={handleOpenModal(
                                original.id,
                                null,
                                original.cburlreg,
                                original.cburldep,
                                original.cburldepmoney
                            )}
                        >
                            postback
                        </Button>
                        <Button
                            className={classNames('ml-1 button-sm', isPromos && 'd-none')}
                            onClick={() => relivePromo(original.id)}
                        >
                            relive
                        </Button>
                    </div>
                )
            },
            width: isPromos ? 310: 174
        }
    ];

    if (loading) {
        return <ShortPreloader />;
    }

    return (
        <div className="promo-list__wrap s-table">
            <ReactTable
                data={data}
                columns={columns}
                showPagination={false}
                pageSize={data.length}
            />
        </div>
    )
}

PromoList.defaultProps = {
    isPromos: false,
    data: [],
};

PromoList.propTypes = {
    isPromos: PropTypes.bool,
    data: PropTypes.array,
    loading: PropTypes.bool,
    archivePromo: PropTypes.func,
    relivePromo: PropTypes.func,
};

export default PromoList;

