import React from 'react';
import PropTypes from 'prop-types';
import { OuterHeader } from '../../components/Header';
import { SignIn, SignUp, AuthModalState } from '../../components/Auth';
import Footer from '../../components/Footer';

const OuterLayout = ({children}) => {
    return (
        <AuthModalState>
             <OuterHeader />
                {children}
             <Footer />
             <SignIn />
             <SignUp />
        </AuthModalState>
    );
};

OuterLayout.propTypes =  {
    children: PropTypes.node.isRequired
};

export default OuterLayout;
