import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FakeCheckbox from '../../Form/FakeCheckbox';
import {ReactComponent as LogoIco} from '../../../assets/img/KB_LOGO_GS.svg';
import kb from '../../../assets/img/KB_image.png';
import './PromoBrand.scss';

function PromoBrand({ brand, setPromo }) {
    const [active, setActive] = useState(brand);

    const handleClick = brandName => {
        setActive(brandName);
        setPromo(brandName, 'brand')
    };

    return (
        <div className="row brands brand__wrapper">
            <div className="col-12 col-md-6 col-lg-4 brand__item text-center"
                 onClick={() => handleClick('kingBilly')}
            >
                <div className="brand__img">
                    <FakeCheckbox isActive={'kingBilly' === active}>
                        <img src={kb} alt="" />
                    </FakeCheckbox>
                </div>
                <div className="brand__logo">
                    <LogoIco />
                </div>
            </div>
        </div>
    );
}

PromoBrand.propTypes = {
    brand: PropTypes.string.isRequired,
    setPromo: PropTypes.func.isRequired
};

export default PromoBrand;
