import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Board from '../../components/Board';
import Content from '../../components/Content';
import MoneyBalance from "../../components/MoneyBalance";
import PromoFilter from '../../components/PromoList/PromoFilter';
import { PreviewState, PreviewModal } from '../../components/PromoList/PreviewModal';
import PromoList from '../../components/PromoList';
import { getPromo, relivePromo } from "../../actions";
import './PromosArchive.scss';

function PromosArchive({ hash, getPromo, relivePromo, loading, data }) {

    useEffect(() => {
        getPromo();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="promos-archive">
            <Board title="Promos archive">
                <div className="row">
                    <div className="col-12 col-xl-6">
                        <MoneyBalance
                            mode={1}
                            boardClassName="d-none d-xl-flex"
                        />
                    </div>
                    <PromoFilter
                        className="col-12 col-xl-6"
                    />
                </div>
            </Board>
            <Content>
                <PreviewState>
                    <PromoList
                        hash={hash}
                        loading={loading}
                        data={data}
                        relivePromo={relivePromo}
                    />
                    <PreviewModal />
                </PreviewState>
            </Content>
        </div>
    )
}

PromosArchive.propTypes = {
    hash: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    getPromo: PropTypes.func.isRequired,
    relivePromo: PropTypes.func.isRequired
};

const mapDispatchToProps = ( dispatch, { hash } ) => {
    return {
        getPromo: () => dispatch(getPromo({
            hash,
            action: 'archive'
        })),
        relivePromo: (id) => {
            dispatch(relivePromo({
                hash,
                id,
                action: 'undelete'
            }))
        },
    }
};

export default connect(
    null,
    mapDispatchToProps
)(PromosArchive);