import {
    DELIVERY_REQUEST,
    DELIVERY_SUCCESS,
    DELIVERY_FAILURE
} from '../../constants/actionTypes';

const initialState = {
    data: [],
    error: null,
    loading: false,
};

export default ( state = initialState, { type, payload } ) => {
    switch (type) {
        case DELIVERY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELIVERY_SUCCESS:
            return {
                ...state,
                data: payload.data,
                loading: false
            };
        case DELIVERY_FAILURE:
            return {
                ...state,
                error: 'some error',
                data: [],
                loading: false
            };
        default:
            return state;
    }
};
