import React, { useEffect, Suspense } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ShortPreloader from '../../components/ShortPreloader';

const PublicRouter = ({ component: Component, layout: Layout, location: { pathname }, lazy, ...rest }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Route
            {...rest}
            render={props => {
                if (Layout) {
                    return (
                        <Layout>
                            <TransitionGroup>
                                <CSSTransition
                                    key={rest.history.location.key}
                                    timeout={444}
                                    classNames="fade"
                                >
                                    <>
                                        {lazy ?
                                            <Suspense fallback={<ShortPreloader />}>
                                                <Component {...props} />
                                            </Suspense>:
                                            <Component {...props} />}
                                    </>
                                </CSSTransition>
                            </TransitionGroup>
                        </Layout>
                    )
                }
            }}
        />
    )
};

export default withRouter(PublicRouter);