import React from 'react';
import {string} from 'prop-types'
import Flag from '../../../Flag';
import {timeCreated} from './util'
import './PromoLinkInfo.scss';

const PromoLinkInfo = ({promoName, type, lang}) => {

    return (
        <div className="mt-4 row promo-info ">
            <div className="col-12 col-md-8">
                <div className="row">
                    <div className="col-6 promo-info__item">
                        <div className="text-small">
                            <span>Type: </span>
                            <span>{type}</span>
                        </div>
                    </div>
                    <div className="col-6 promo-info__item">
                        <div className="text-small">
                            <span>CEO-targeting:</span>
                            <Flag lang={lang} />
                        </div>
                    </div>
                    <div className="col-6 promo-info__item">
                        <div className="text-small">
                            <span>Program:</span>
                            <span>{promoName || 'default'}</span>
                        </div>
                    </div>
                    <div className="col-6 promo-info__item">
                        <div className="text-small">
                            <span>Created:</span>
                            <span>{timeCreated().date} {timeCreated().time}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PromoLinkInfo.propTypes = {
    promoName: string,
    type: string.isRequired,
    lang: string.isRequired
};

export default PromoLinkInfo;
