import React from 'react';
import {number, func} from 'prop-types';
import './Pagination.scss';
import {ReactComponent as ArrowIco} from '../../../../assets/img/down_FAQ.svg';

function noop() {}

function Pagination({page, pages, onPageChange}) {
    return (
        <div  className="t-pagination">
            <div className="t-pagination__text text-small">
                Showing {page + 1} to {page + 1} of {pages} entries
            </div>
            <div className="t-pagination__controller">
                <div
                    className="t-pagination__arrow"
                    onClick={() => page !== 0 ? onPageChange(page - 1): noop}
                >
                    <ArrowIco />
                </div>
                <div className="t-pagination__num text">
                    {page + 1}
                </div>
                <div
                    className="t-pagination__arrow"
                    onClick={() => page + 1 !== pages ? onPageChange(page + 1): noop}
                >
                    <ArrowIco />
                </div>
            </div>
        </div>
    )
}

Pagination.propTypes = {
    page: number.isRequired,
    pages: number.isRequired,
    onPageChange: func.isRequired
};

export default Pagination;
