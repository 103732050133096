import React from 'react';
import classNames from 'classnames';
import {string} from 'prop-types';
import {useTheme} from '../../hooks/useTheme';
import {ReactComponent as SunIco} from '../../assets/img/sun.svg';
import {ReactComponent as MoonIco} from '../../assets/img/moon.svg';
import './ThemeToggle.scss';


function ThemeToggle({className}) {
    const {theme, toggleTheme} = useTheme();

    return (
        <div
            className={classNames('theme-toggle', className)}
            onClick={toggleTheme}
        >
            <button className={theme === 'light' ? 'light': null}>
                <SunIco />
                <MoonIco />
            </button>
        </div>
    );
}

ThemeToggle.propTypes = {
    className: string
};

export default ThemeToggle;
