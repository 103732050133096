import axios from "axios";
import { handler } from '../utils';

const URL = 'https://web.lucky4winners.com/api/?type=payments';

export function getPayments(params) {
    return dispatch => {
        dispatch(handler.REQUEST('PAYMENT'));
        axios({
            method: 'post',
            url: URL,
            data: params,
            headers: {
                'content-Type': 'application/json',
            }
        })
            .then( res => dispatch(handler.SUCCESS('PAYMENT', res)) )
            .catch( err => dispatch(handler.FAILURE('PAYMENT', err)) )
    }
}
