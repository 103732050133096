import React from 'react';
import './About.scss'
import logo_1 from '../../../assets/img/LIFETIME_COMMISSION_icon.svg';
import logo_2 from '../../../assets/img/MARKETING_TOOLS_icon.svg';
import logo_3 from '../../../assets/img/STRONG_CONVERSION_icon.svg';

const content = [
    {
        img: logo_1,
        title: 'Lifetime commission',
        text: 'We offer permanent commissions for referring new\n' +
            '                        depositors to our casino website. You are free to\n' +
            '                        choose any type of commissions among three different\n' +
            '                        plans: RevShare, CPA, and Hybrid. Transparency and\n' +
            '                        absence of any hidden fees guaranteed.'
    },
    {
        img: logo_2,
        title: 'Marketing tools',
        text: 'Promote our website and get a fair income with ease,\n' +
            '                        using our advanced marketing and reporting tools.\n' +
            '                        Optimize your media management by utilizing an\n' +
            '                        intuitive administrative panel: advanced statistics,\n' +
            '                        modern design, custom-made landing pages, and banners\n' +
            '                        are at your service.'
    },
    {
        img: logo_3,
        title: 'Strong conversion',
        text: 'An individual approach to every affiliate gives us\n' +
            '                        a possibility to better understand what your players\n' +
            '                        need. Such a strategy helps us to improve the conversion\n' +
            '                        rates and enhance player retention. While you work on\n' +
            '                        getting revenues, we take care of the rest.'
    }
];

const About = () => (
    <div className="about" id="about">
        <div className="pt-3 pb-3 pt-md-5 pb-md-5 container">
            <div className="container-title">
                who we are
            </div>
            <div className="row">
                <div className="col-12 pb-3 pb-md-5 text">
                    Kings Club is a land of casino affiliate business where
                    kings and queens from all over the world can get income
                    effortlessly by advertising our exceptional casino websites.
                    Our wise and experienced affiliate managers serve the goal
                    to optimize affiliate marketing performance for you and help
                    you get truly royal profits.
                </div>

                {content.map(item => (
                    <div key={item.title} className="col-12 col-md-6 col-lg-4 mt-5 mt-lg-0 about__item">
                        <div className="about__img-wrap">
                            <img src={item.img} alt="" />
                        </div>
                        <div className="about__head-text text-head">
                            {item.title}
                        </div>
                        <div className="about__text text">
                            {item.text}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default About;
