import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ClampLines from 'react-clamp-lines';
import { PIECE_OF_NEWS } from '../../constants/actionTypes';
import './News.scss';

const News = ({newsList, getOneNews, className }) => (
    <div className={`news ${className ? className: 'news-all'}`}>
        <div className="pt-3 pb-3 pt-md-5 pb-md-5 container">
            <div className="container-title">
                news
            </div>
            <div className="row">
                {
                    newsList.map( item => (
                        <div key={item.id} className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="news__date text-small">
                                {item.date}</div>
                            <div className="news__title text-head">
                                {item.title}
                            </div>
                            <div
                                className="news__img"
                                style={{backgroundImage: `url(${item.thumbnail})`}}
                            >
                            </div>
                            <ClampLines
                                className="news__text text"
                                text={item.text}
                                lines={3}
                                ellipsis="..."
                                buttons={false}
                            />
                            <Link
                                to={`/news/${item.id}`}
                                className="mt-4 button-default button-bg"
                                onClick={getOneNews(item.id)}
                            >
                                more
                            </Link>
                        </div>
                    ))
                }
            </div>
            {
                className &&
                <Link
                    to={'/news'}
                    className="more_btn">
                    read all news
                </Link>
            }
        </div>
    </div>
);

News.propTypes = {
    newsList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        thumbnail: PropTypes.string,
        text: PropTypes.string,
        data: PropTypes.string
    })),
    getOneNews: PropTypes.func.isRequired,
    className: PropTypes.string
};

News.defaultProps = {
    newsList: null,
    className: null
};

const mapDispatchToProps = dispatch => ({
    getOneNews: (id) => () => (
        dispatch({
            type: PIECE_OF_NEWS,
            payload: id
        })
    )
});

export default connect(({news: { newsList }}) => ({newsList}), mapDispatchToProps)(News);
