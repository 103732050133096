import React, {useContext} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import exportFromJson from 'export-from-json';
import {StatsContext} from '../../../context';
import {ReactComponent as SaveIco} from '../../../assets/img/save.svg';
import './SaveExcel.scss';

function SaveExcel({hash}) {
    const {date: {from, to}, option} = useContext(StatsContext);

    const fetchData = async () => {
        try {
            const fetched = await axios({
                method: 'post',
                url: 'https://web.lucky4winners.com/api/?type=stats',
                data: JSON.stringify({
                    hash: hash,
                    from, to,
                    option,
                    limit: 111
                }),
            });

            saveExcel(fetched.data.data);
        } catch (e) {}
    };

    const saveExcel = (data) => {
        const fileName = 'download';
        const exportType = 'xls';

        exportFromJson({data, fileName, exportType});
    };

    return (
        <div
            className="btn button-default btn-excel"
            onClick={fetchData}
        >
            <SaveIco /> excel
        </div>
    );
}

SaveExcel.propTypes = {
    hash: PropTypes.string.isRequired
};

export default connect(({logInfo: {hash}}) => ({hash}))(SaveExcel);