import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import {CSSTransition, TransitionGroup} from "react-transition-group";

const PrivateRouter = ({ component: Component, layout: Layout, logged, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (logged) {
                return (
                    <Layout>
                        <TransitionGroup>
                            <CSSTransition
                                key={rest.location.key}
                                timeout={444}
                                classNames="fade-inner"
                            >
                                <Component {...props} />
                            </CSSTransition>
                        </TransitionGroup>
                    </Layout>
                )
            }

            return <Redirect to={'/'} />
        }}
    />
);


export default connect(({logInfo: { hash: logged }}) => ({logged}))(PrivateRouter);