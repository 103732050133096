import React from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';
import ShortPreloader from '../../ShortPreloader';
import './LoadButton.scss';

const LoadButton = ({ onClick, className, children, isLoad }) => (
    <button
        type="submit"
        className={classNames('button-default', className)}
        onClick={onClick}
        disabled={isLoad}
    >
        {isLoad ? <ShortPreloader className="short-preloader-btn" /> : children }
    </button>
)

LoadButton.defaultProps = {
    className: null,
    onClick: null,
};

LoadButton.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    isLoad: PropTypes.bool.isRequired,
};

export default LoadButton;