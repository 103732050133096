import img0 from '../assets/img/inner-menu/dash.png';
import img1 from '../assets/img/inner-menu/pay.png';
import img2 from '../assets/img/inner-menu/stat.png';
import img3 from '../assets/img/inner-menu/tracking.png';
import img4 from '../assets/img/inner-menu/promos.png';
import img5 from '../assets/img/inner-menu/sub-af.png';
import img6 from '../assets/img/inner-menu/prof.png';

const outerNavigation = [
    { label: 'brands', url: '/#brands', key: 'brands' },
    { label: 'about us', url: '/#about', key: 'about_us' },
    { label: 'commissions', url: '/#commissions', key: 'commissions' },
    { label: 'testimonials', url: '/testimonials', key: 'testimonials' },
    { label: 'news', url: '/news', key: 'news' },
    { label: 'faq', url: '/faq', key: 'faq' },
    { label: 'contacts', url: '/#contacts', key: 'contacts' },
];

const innerNavigation = [
    {
        img: img6,
        label: 'profile',
        url: '/profile',
        key: 'profile'
    },
    {
        img: img0,
        label: 'dashboard',
        url: '/dashboard',
        key: 'dashboard'
    },
    {
        img: img1,
        label: 'payments',
        url: '/payments',
        key: 'payments'
    },
    {
        img: img2,
        label: 'stats',
        url: '/stats',
        key: 'stats'
    },
    {
        img: img3,
        label: 'tracking links',
        url: '/new-promo',
        key: 'tracking_links'
    },
    {
        img: img4,
        label: 'list of promos',
        url: '/promos',
        key: 'promos'
    },
    {
        img: img5,
        label: 'sub-affiliate link',
        url: '/sub-affiliate',
        key: 'sub_affiliate'
    },
];

export {
    outerNavigation,
    innerNavigation
};
