import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Board from '../../components/Board';
import { PaymentForm, PaymentTable } from '../../components/Payment';
import Content from '../../components/Content';
import ShortPreloader from "../../components/ShortPreloader";

function Payments (props) {

    const {
        hash, data, loading,
        error, getPayments,
        payInfo
    } = props;

    useEffect( () => {
        getPayments({ hash })
        // eslint-disable-next-line
    }, []);

    return (
        <div className="payments">
            <Board title="payments">
                <PaymentForm
                    hash={hash}
                    payInfo={payInfo}
                />
            </Board>
            <Content>
                { loading && !error ?
                    <ShortPreloader /> :
                    <PaymentTable data={data} /> }
            </Content>
        </div>
    );
}

Payments.propTypes = {
    hash: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    payInfo: PropTypes.string,
    error: PropTypes.string,
    getPayments: PropTypes.func.isRequired
};

export default Payments;
