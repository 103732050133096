import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomChart from '../../CustomChart';
import GraphicFilter from './GraphicFilter';
import './StatsGraphic.scss';

function StatsGraphic ({ data }) {
    const [ fValue, setFValue ] = useState( 'NGR');
    const [ sValue, setSValue ] = useState( 'Clicks');

    const graphData = data.map( i =>
        ({
            name: i.Date,
            bar: parseFloat(i[fValue]).toFixed(2) >= 0 ?
                parseFloat(i[fValue]).toFixed(2): 0,
            line: parseFloat(i[sValue]).toFixed(2) >= 0 ?
                parseFloat(i[sValue]).toFixed(2): 0,
        })
    ).reverse();

    return (
        <div className="stats__chart">
            <GraphicFilter
                data={data.length ? data: [{}]}
                fValue={fValue}
                sValue={sValue}
                setFvalue={setFValue}
                setSvalue={setSValue}
            />
            <CustomChart
                data={graphData}
                filterData={{fValue, sValue}}
            />
        </div>
    )
}

StatsGraphic.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};

export default StatsGraphic;
