import { connect } from 'react-redux';
import Promos from "./Promos";

export default connect(
    ({
         logInfo: {
             hash
         },
         promosList: {
             loading,
             data,
             filterText
         }
     }) => ({
        hash,
        loading,
        data: data.filter( d => d.PromoCode.includes(filterText) )
     })
)(Promos);