import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Board from '../../components/Board';
import { PostbackForm } from '../../components/Postback';
import Content from '../../components/Content';
import './PostbackSettings.scss';

const PostbackSettings = ({ hash, data, getPromo, postBack }) => {

    useEffect(() => {
        getPromo({ hash });
        // eslint-disable-next-line
    }, []);

    const dropList = data.map( item => ({ value: item.id, label: item.Name }) ) || [];

    return (
        <div  className="p_back">
            <Board title="Postback settings">
                <PostbackForm
                    hash={hash}
                    dropList={dropList}
                    postBack={postBack}
                />
            </Board>
            <Content>
                <div className="text">
                    To use postback you have to add a [S2S]-field to your link.
                    For example: http://yourdomain.com/postback?data=[S2S]
                    Postback parameter value can not be longer than 2000 symbols.
                    [S2S]-field in the url will be replaced by the postback parameter value.
                    If promo postback settings are inactive user postback settings
                    will be used(if they are active).
                </div>
            </Content>
        </div>
    );
};

PostbackSettings.propTypes = {
    hash: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    getPromo: PropTypes.func.isRequired,
    postBack: PropTypes.object.isRequired,
};

export default PostbackSettings;
