import { promoTypes } from '../../constants';
import mainImage from "../../assets/img/d-link.png";
import termsImage from  "../../assets/img/terms.png";

const handlers = {
    direct: ({ Creative_Link: creative, Link }) => {
        if ( creative && creative.includes('mp4') ) {
            return (
                `<a href="${Link}">
                <video width="300" autoPlay>
                <source src="${creative}" type="video/mp4" />
                </video>
                </a>`
            )
        } else if ( creative ) {
            return (
                `<a href="${Link}">
                <img src="${creative}" alt=""/>
                </a>`
            )
        }

        return Link;
    },
    landings: ({ Creative_Link: creative, Link }) => {
        if ( creative && creative.includes('mp4') ) {
            return (
                `<a href="${Link}">
                <video width="300" autoPlay>
                <source src="${creative}" type="video/mp4" />
                </video>
                </a>`
            )
        } else if ( creative ) {
            return (
                `<a href="${Link}">
                <img src="${creative}" alt="" />
                </a>`
            )
        }

        return Link;
    },
    DEFAULT: () => '...'
};

const getClipboardText = ({ Type: type, ...args }) => {
    const handle =  handlers[type === 'terms' ? 'direct': type] || handlers.DEFAULT;

    return handle( args );
};

const getPreview = ({
    Creative_Link: creative,
        Landing: landing,
        Type: type
 }) => {
    switch (type) {
        case promoTypes.direct:
        case promoTypes.terms:
            if ( creative && creative.includes('mp4') ) {
                return (
                    `<a href="${ type === promoTypes.direct ?
                        'https://www.kingbillycasino.com' : 
                        'https://www.kingbillycasino.com/ru/terms-and-conditions' 
                    }" target="blank">
                        <video width="100%" autoPlay={true}>
                            <source src="${creative}" type="video/mp4" />
                        </video>
                    </a>`
                )
            }
            else if ( creative ) {
                return (
                    `<a href="${ type === promoTypes.direct ?
                        'https://www.kingbillycasino.com' :
                        'https://www.kingbillycasino.com/ru/terms-and-conditions' }"
                       target="blank"
                    >
                        <img
                            className="preview__img"
                            src="${creative}"
                            alt=""
                        />
                    </a>`
                )
            }

            return (
                `<a
                    className="text preview__naked-link"
                    href="${ type === promoTypes.direct ?
                        'https://www.kingbillycasino.com' :
                        'https://www.kingbillycasino.com/ru/terms-and-conditions' }"
                    target="blank"
                >
                        <img className="preview__img" src="${type === promoTypes.direct ? mainImage : termsImage}" alt="" />
                </a>`
            );
        case promoTypes.landings:
            if ( creative && creative.includes('mp4') ) {
                return (
                    `<a href="https://${landing}" target="blank">
                        <video width="100%" autoPlay>
                            <source src="${creative}" type="video/mp4" />
                        </video>
                    </a>`
                )
            } else if ( creative ) {
                return (
                    `<a href="https://${landing}" target="blank">
                        <img className="preview__img" src="${creative}" alt="" />
                    </a>`
                )
            }

            return (
                `<a
                    className="text preview__naked-link"
                    href="https://${landing}"
                    target="blank"
                >
                        <img className="preview__img" src="${creative}" alt="" />
                </a>`
            );
        default:
            return null
    }
};

export { getClipboardText, getPreview };