import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import FakeCheckbox from '../../../Form/FakeCheckbox';
import Button from '../../../Button';
import ShortPreloader from '../../../ShortPreloader';
import './BannerLayout.scss';

function BannerLayout({ layout, data: { data, loading }, setPromo }) {
    const [active, setActive] = useState(layout);
    const [visible, setVisible] = useState(20);

    const handleClick = l => {
        setActive(l.name);
        setPromo(l.name, 'layout');
        setPromo({ link: l.link }, 'isWrapped');
    };

    if ( loading ) {
        return <ShortPreloader />;
    }

    return (
        <>
            <div className="row l-banner">
                {data.slice(0, visible).map((l, id) => (
                    <div key={id} className="col-12 l-banner__item mb-4">
                        <div className="l-banner__img-wrap">
                            <img
                                className="l-banner__image"
                                src={l.preview || l.link}
                                alt=""
                            />
                        </div>
                        <div
                            className="text-small l-banner__size"
                            onClick={() => handleClick(l)}
                        >
                            <FakeCheckbox isActive={active === l.name}>
                                size:
                                <span className="text"> {l.width} </span>
                                x
                                <span className="text"> {l.height}</span>
                            </FakeCheckbox>
                        </div>
                    </div>
                ))}
                <Button
                    className={classNames('layout__more', data.length === 0 ? 'd-none': null)}
                    onClick={() => setVisible(visible + 20)}>
                    more
                </Button>
            </div>
        </>
    );
}

BannerLayout.propTypes = {
    data: PropTypes.shape({
        loading: PropTypes.bool,
        data: PropTypes.array,
    }).isRequired,
    layout: PropTypes.string.isRequired,
    setPromo: PropTypes.func.isRequired,
};

export default BannerLayout;
