import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UnLogged from './UnLogged';
import Logged from './Logged';
import './AuthController.scss';

const AuthController = ({ logged, data, className, onClick }) => (
    <div className={className} onClick={onClick}>
        {logged ? <Logged userName={data.login} /> : <UnLogged/>}
    </div>
);

AuthController.defaultProps = {
    className: null,
    onClick: null,
};

AuthController.propTypes = {
    logInfo: PropTypes.shape({
        data: PropTypes.objectOf(PropTypes.string),
    }),
    className: PropTypes.string,
    onClick: PropTypes.func,
};

const mapStateToProps = ({logInfo: { hash: logged, data }}) => ({logged, data});

export default connect(mapStateToProps)(AuthController);