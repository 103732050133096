import React from 'react';
import PropTypes from 'prop-types';
import Board from '../../components/Board';
import Content from '../../components/Content';
import {
    DashboardChart,
    DashboardCalendar,
} from '../../components/Dashboard';
import MoneyBalance from '../../components/MoneyBalance';
import TotalBalance from '../../components/Dashboard/TotalBalance';
import StatsState from '../../components/Stats/StatsState';
import './Dashboard.scss';

const Dashboard = ({ hash, getStats, data, total }) => (
   <div className="dashboard">
       <StatsState hash={hash} getStats={getStats}>
           <Board title="dashboard">
               <div className="row">
                   <MoneyBalance
                       boardClassName="d-none d-md-flex pl-0 pr-0 col-12 col-md-5"
                       mode={1}
                   />
                    <TotalBalance
                        className="pl-2 pr-2 pl-md-0 pr-md-0 col-md-3"
                        total={total}
                    />
                   <DashboardCalendar
                       className="col-12 col-md-4 dashboard__form"
                   />
               </div>
           </Board>
           <Content className="pb-0">
               <DashboardChart
                   data={data}
               />
           </Content>
       </StatsState>
   </div>
);

Dashboard.propTypes = {
    hash: PropTypes.string.isRequired,
    data: PropTypes.array,
    total: PropTypes.object,
    getStats: PropTypes.func.isRequired
};

export default Dashboard;