import React, {memo} from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';
import { Accordion } from 'react-bootstrap';
import Sticky from 'react-stickynode';
import MoneyBalance from '../../MoneyBalance';
import ArrowDropdown from '../../ArrowDropdown';
import { innerNavigation } from '../../../constants/navigation';
import './InnerMenu.scss';
import img from '../../../assets/img/inner-menu/other.png';
import postback from '../../../assets/img/inner-menu/pback.png';
import archiv from '../../../assets/img/inner-menu/archiv.png';

const InnerMenu = memo(() => (
    <div className="inner-menu">
        <Sticky top={0}>
            <div className="inner-menu__link">
                {innerNavigation.map((item, id) => {
                    return (
                        <Link key={id} to={item.url}>
                            <img
                                src={item.img}
                                className="inner-menu__symbol"
                                alt=""
                            />
                            {item.label}
                        </Link>
                    )
                })}
                <Accordion>
                    <Accordion.Toggle eventKey="0">
                        <ArrowDropdown>
                            <img
                                src={img}
                                className="inner-menu__symbol"
                                alt=""
                            />
                            <span>OTHER TOOLS</span>
                        </ArrowDropdown>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <>
                            <Link to="/postback-settings">
                                <img
                                    src={postback}
                                    className="inner-menu__symbol"
                                    alt=""
                                />
                                <span>Postback settings</span>
                            </Link>
                            <Link to="/promos-archive">
                                <img
                                    src={archiv}
                                    className="inner-menu__symbol"
                                    alt=""/>
                                <span>Archived promos</span>
                            </Link>
                        </>
                    </Accordion.Collapse>
                </Accordion>
            </div>
            <MoneyBalance mode={0}/>
        </Sticky>
    </div>
));

export default InnerMenu;