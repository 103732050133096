import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from '../../Calendar';
import Button from '../../Button';
import { StatsContext } from '../../../context';
import './DashboardCalendar.scss';

function DashboardCalendar({ className }) {
    const {
        onSubmit,
        date: { to, setTo, from, setFrom }
    } = useContext(StatsContext);

    return (
        <form
            className={className}
            onSubmit={onSubmit}
        >
            <Calendar
                sets={{
                    to, setTo,
                    from, setFrom
                }}
            />
            <div className="dashboard-btn">
                <Button className="dashboard__submit mt-4">
                    Apply
                </Button>
            </div>
        </form>
    )
}

DashboardCalendar.defaultProps = {
    className: null
};

DashboardCalendar.propTypes = {
    className: PropTypes.string
};

export default DashboardCalendar;