import { connect } from 'react-redux';
import Payments from './Payments';
import { getPayments } from '../../actions';


export default connect(
    ({
         logInfo: {
             hash
         },
         paymentsList: {
             data,
             loading,
             error,
             payInfo
         },
    }) => ({ data, loading, error, hash, payInfo }),
    { getPayments }
)(Payments)
