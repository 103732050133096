import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Sticky from 'react-stickynode';
import Button from '../../../Button';
import { promoTypes as types } from '../../../../constants/promo';
import './StepTitle.scss';

const StepTitle = ({ step, type, disabled, prevStep, nextStep }) => {
    let text = [];

    switch (step) {
        case 1:
            text = ['Program BRAND', 'select brand'];
            break;
        case 2:
            text = ['TYPE OF PROMO', 'select type of promo'];
            break;
        case 3:
            if ( type === types.landings ) {
                text = ['TYPE OF PROMO', 'select LANDING PAGE'];
            } else if ( type === types.brandings ) {
                text = ['TYPE OF PROMO', 'select BRANDING']
            } else if ( type === types.banners ) {
                text = ['TYPE OF PROMO', 'select BANNER']
            } else if ( type === types.video ) {
                text = ['TYPE OF PROMO', 'select VIDEO']
            }
            break;
        case 4:
            text = ['PROMO', 'select delivery page'];
            break;
        default:
            text = ['get', 'link']
    }


    return (
        <div className="step__title">
            <Sticky top={0}>
                <span className="text">step {step}</span>
                <span className="text-small">{text[0]}</span>
                <span className="text-small">{text[1]}</span>

                <div className={classNames(step === 5 ? 'd-none': 'd-inline')}>
                    <Button
                        className="ml-5 text-small step__title-btn step__btn-left"
                        onClick={step !== 1 ? prevStep : null}
                    >
                        back
                    </Button>
                    <Button
                        className={classNames(
                            'text-small step__title-btn step__btn-right',
                            !disabled ? 'step__disabled' : null
                        )}
                        onClick={disabled ? () => nextStep(): null}
                    >
                        next
                    </Button>
                </div>
            </Sticky>
        </div>
    );
};

StepTitle.propTypes = {
    step: PropTypes.number.isRequired,
    type: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
};

export default memo(StepTitle);
