import React from 'react';
import PropTypes from 'prop-types';
import './CheckBox.scss';

const CheckBox = ({name, refer}) => (
    <div className="custom-checkbox">
        <input
            type="checkbox"
            id={name}
            defaultChecked={true}
            name={name}
            ref={refer}
        />
        <label htmlFor={name}></label>
    </div>
);

CheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    refer: PropTypes.func,
    labelText: PropTypes.string,
};


export default CheckBox;
