import React from 'react';
import PropTypes from 'prop-types';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import AuthController from '../../Auth/AuthController';
// import ThemeToggle from '../../ThemeToggle';
import { outerNavigation, innerNavigation } from '../../../constants';
import './MobileMemu.scss';

const MobileMenu = ({closeMenu, inner}) => {

    const navigation = () => {
        if (inner) {
            const innerNav = [
                ...innerNavigation,
                {label: 'Postback settings', url: '/postback-settings', key: 'post-set'},
                {label: 'Archived promos', url: '/promos-archive', key: 'promo-archive'}
            ];

            return innerNav;
        }

        return outerNavigation;
    };

    return (
        <div className="mobile-menu__wrap">
            <AuthController
                className="mobile-auth"
                onClick={closeMenu}
            />
            <div className="mobile-menu__links">
                {
                    navigation().map(route =>
                        <NavLink
                            to={route.url}
                            key={route.key}
                            className="text mobile-menu__item"
                            onClick={closeMenu}
                        >
                            {route.label}
                        </NavLink>
                    )
                }
            </div>
            {/*{inner && <ThemeToggle />}*/}
        </div>
    )
};

MobileMenu.defaultProps = {
  inner: false
};

MobileMenu.propTypes = {
    closeMenu: PropTypes.func.isRequired,
    inner: PropTypes.bool,
};

export default MobileMenu;