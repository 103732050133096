import { connect } from 'react-redux';
import PostbackSettings from './PostbackSettings';
import { getPromo } from '../../actions';

export default connect(
    ({
        logInfo: {
            hash
        },
         promosList: {
             loading,
             data,
         },
        postBack
     }) => ({ hash, loading, data, postBack }),
    { getPromo }
)(PostbackSettings);