import React from 'react';
import {string, func} from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut } from '../../../../actions';
import {ReactComponent as UserIco} from '../../../../assets/img/user.svg';
import {ReactComponent as ExitIco} from '../../../../assets/img/out.svg';
import './Logged.scss';

const Logged = ({ logOut, userName }) => {

    return (
        <>
            <Link
                to="/dashboard"
                className="text header-auth__user"
            >
                {userName}
                <UserIco />
            </Link>
            <Link
                to="/"
                className="text header-auth__log-out"
                onClick={() => logOut()}
            >
                exit
                <ExitIco />
            </Link>
        </>
    )
};

Logged.propTypes = {
    logOut: func.isRequired,
    userName: string.isRequired
};

export default connect(
    null,
    { logOut }
)(Logged);