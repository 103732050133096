import React from 'react';
import PropTypes from 'prop-types';
import './Content.scss';

const Content = ({children, className}) => {
    return (
        <div className={`inner__container ${className}`}>
            {children}
        </div>
    );
};

Content.defaultProps = {
    className: ''
};

Content.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

export default Content;
