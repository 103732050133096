import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from "../../../Button";
import './PromoNavigation.scss';

const Pagination = ({ step, disabled, nextStep, prevStep }) => (
    <div className={classNames('link-steps mt-5', step === 5 ? 'd-none': null )}>
        <Button
            className={ step === 1 ? 'prev d-none': 'prev' }
            onClick={() => prevStep()}
        >
            prev
        </Button>

        <Button
            className={!disabled ? 'disabled' : null}
            onClick={disabled ? () => nextStep(): null}
        >
            next
        </Button>
    </div>
);

Pagination.propTypes = {
    step: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
};

export default memo(Pagination);
