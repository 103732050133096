import React from 'react';
import PropTypes from 'prop-types';

const Error = ({error}) => {
    return (
        <div
            className="text-head text-center"
            style={{color: '#f9c942'}}>
            {error}
        </div>
    );
};

Error.propTypes = {
    error: PropTypes.string
};

export default Error;
