import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PreviewContext } from './previewContext';

function PreviewState({children}) {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ preview, setPreview ] = useState({});

    const openModal = () => {
        if ( !isOpen ) {
            setIsOpen(true);
        }
    };

    const closeModal = () => {
        if ( isOpen ) {
            setIsOpen(false);
        }
    };

    return (
        <PreviewContext.Provider value={{
            isOpen,
            openModal,
            closeModal,
            preview,
            setPreview
        }} >
            {children}
        </PreviewContext.Provider>
    )
}

PreviewState.propTypes = {
  children: PropTypes.node.isRequired
};

export default PreviewState;
