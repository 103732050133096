import React, { useState, memo } from 'react';
import { Collapse as MobileMenuWrap } from 'react-bootstrap';
import Logo from '../Logo';
import Toggler from '../Toggler';
import UserCongrats from './UserCongrats';
import ThemeToggle from '../../ThemeToggle';
import { default as MoneyBalance } from '../../MoneyBalance';
import { MobileMemu } from '../../Menu';
import { AuthController } from '../../Auth';
import history from '../../../router/history';
import './InnerHeader.scss';

const options = [
    { value: 'king-billy', label: 'King Billy' }
];

function InnerHeader() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <>
            <header className="row ml-0 mr-0 header inner-header">
                <Logo
                    className="col-6 col-xl-2"
                    onClick={() => history.push('/')}
                />
                <UserCongrats
                    className="col-lg-4 d-none d-xl-flex"
                    brands={options}
                />
                <MoneyBalance
                    className="col-lg-3 d-none d-xl-flex"
                    mode={3}
                />
                <ThemeToggle
                    className="d-none
                    d-lg-block
                    col-lg-1"
                />
                <Toggler
                    className="col-6 col-lg-5 d-block d-xl-none"
                    clicked={isOpen}
                    onClick={toggleMenu}
                />
                <AuthController
                    className="
                    col-lg-2 d-none
                    d-xl-flex header-auth"
                />
            </header>
            <MobileMenuWrap
                className="mobile-menu"
                in={isOpen}
            >
                <div>
                    <MobileMemu inner={true} closeMenu={toggleMenu} />
                </div>
            </MobileMenuWrap>
        </>
    );
}

export default memo(InnerHeader);
