import React from 'react';
import PropTypes from 'prop-types';
import './IsError.scss';

const IsError = ({ errorText, className }) => {

    if ( !errorText ) return null;

    return (
        <div className={`text-small text-error ${className}`}>
            {errorText}
        </div>
    );
};


IsError.defaultProps = {
    className: null
};

IsError.propTypes = {
    className: PropTypes.string,
    errorText: PropTypes.string.isRequired
};

export default IsError;
