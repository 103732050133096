const initialState = {
    changed: false,
    changeErr: '',
    resetSuccess: false,
    resetError: '',
}

const handlers = {
    EDIT_SUCCESS:
        (state, { payload }) => ({
            ...state,
            changed: payload.data.status ? true: false,
            changeErr: payload.data.error || ''
        }),
    RESET_SUCCESS:
        (state, { payload }) => ({
            ...state,
            resetSuccess: payload.data.status ? true: false,
            resetError: payload.data.error || ''
        }),
    DEFAULT: state => state
}

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;

    return handle(state, action);
};