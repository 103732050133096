import axios from "axios";
import {
    GET_PROMO_LOADING,
    GET_PROMO_SUCCESS,
    GET_PROMO_ERROR,
    REMOVE_PROMO_LOADING,
    REMOVE_PROMO_SUCCESS,
    REMOVE_PROMO_ERROR,
    RELIVE_PROMO_LOADING,
    RELIVE_PROMO_SUCCESS,
    RELIVE_PROMO_ERROR,
} from '../constants/actionTypes';

const URL = 'https://web.lucky4winners.com/api/?type=offers';

export const getPromo = params => {
    return dispatch => {
        dispatch({ type: GET_PROMO_LOADING });
        axios.post(URL, params, {
            headers: {
                'content-Type': 'application/json',
            }
        })
            .then(
                res => dispatch({
                    type: GET_PROMO_SUCCESS,
                    payload: res
                })
            )
            .catch(
                err => dispatch({
                    type: GET_PROMO_ERROR,
                    payload: err
                })
            )
    }
};

export const archivePromo = params => {
    return dispatch => {
        dispatch({ type: REMOVE_PROMO_LOADING });
        axios.post(URL, params, {
            headers: {
                'content-Type': 'application/json',
            }
        })
            .then(
                res => dispatch({
                    type: REMOVE_PROMO_SUCCESS,
                    payload: res
                })
            )
            .then(
                () => dispatch({
                    type: "REMOVE_PROMO_LOCAL",
                    payload: params.id
                })
            )
            .catch(
                err => dispatch({
                    type: REMOVE_PROMO_ERROR,
                    payload: err
                })
            )
    }
};

export const relivePromo = params => {
    return dispatch => {
        dispatch({ type: RELIVE_PROMO_LOADING });
        axios.post(URL, params, {
            headers: {
                'content-Type': 'application/json',
            }
        })
            .then(
                res => dispatch({
                    type: RELIVE_PROMO_SUCCESS,
                    payload: res
                })
            )
            .then(
                () => dispatch({
                    type: "RELIVE_PROMO_STATIC",
                    payload: params.id
                })
            )
            .catch(
                err => dispatch({
                    type: RELIVE_PROMO_ERROR,
                    payload: err
                })
            )
    }
};
