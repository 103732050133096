import {
    STATS_REQUEST,
    STATS_SUCCESS,
    STATS_FAILURE,
    FILTER_STATS_COLUMNS
} from '../constants/actionTypes';

const initialState = {
    data: [],
    offers: [],
    total: null,
    hiddenCols: [],
    count: 0,
    loading: true,
    error: ''
};

const statsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case STATS_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case STATS_SUCCESS:
            return {
                ...state,
                data: payload.data.data,
                tableData: payload.data.data,
                offers: payload.data.offers,
                total: payload.data.total,
                count: payload.data.count,
                loading: false,
                error: payload.data.error,
            };
        case FILTER_STATS_COLUMNS:
            return {
                ...state,
                hiddenCols: payload
            };
        case STATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: new Error('stats: failure'),
            };
        default:
            return state
    }
};

export default statsReducer;
