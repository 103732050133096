import React from 'react';
import PropTypes from 'prop-types';
import { NavHashLink as Link } from 'react-router-hash-link';
import { outerNavigation } from '../../../constants';
import './DesktopMenu.scss';

const DesktopMenu = ({className}) => (
    <div className={className}>
        {outerNavigation.map(route =>
                <Link
                    to={route.url}
                    smooth
                    activeStyle={{color: '#e0b22f'}}
                    key={route.key}
                    className="header-nav__item"
                >
                    {route.label}
                </Link>)}
    </div>
);

DesktopMenu.defaultProps = {
    className: null,
    inner: false
};

DesktopMenu.propTypes = {
    className: PropTypes.string,
    inner: PropTypes.bool,
};

export default DesktopMenu;
