import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as Ico3} from '../../../assets/img/aside3.svg';
import './AsideBalance.scss';

const AsideBalance = (props) => {
    const {
        moneyStatus: {
            cparate,
            refshare,
            today,
            balance
        }} = props;

    return (
        <div className="aside-balance">
            <div className="aside-balance__item">
                <Ico3 />
                <div className="aside-balance__title text-small">
                    rates
                </div>
                <div className="aside-balance__data">
                    {cparate}/{refshare}
                </div>
            </div>
            <div className="aside-balance__item">
                <Ico3 />
                <div className="aside-balance__title text-small">
                    today
                </div>
                <div className="aside-balance__data">
                    {parseFloat(today).toFixed(2)}
                </div>
            </div>
            <div className="aside-balance__item">
                <Ico3 />
                <div className="aside-balance__title text-small">
                    balance
                </div>
                <div className="aside-balance__data">
                    {parseFloat(balance).toFixed(2)}
                </div>
            </div>
        </div>
    )
};

AsideBalance.propTypes = {
    moneyStatus: PropTypes.objectOf(PropTypes.string).isRequired
};

export default AsideBalance;
