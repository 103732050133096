import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FakeCheckbox from '../../Form/FakeCheckbox';
import { promoTypes as promoType } from '../../../constants';
import {ReactComponent as Ico1} from '../../../assets/img/promo/1.svg';
import {ReactComponent as Ico2} from '../../../assets/img/promo/2.svg';
import {ReactComponent as Ico3} from '../../../assets/img/promo/3.svg';
import {ReactComponent as Ico4} from '../../../assets/img/promo/4.svg';
import {ReactComponent as Ico5} from '../../../assets/img/promo/5.svg';
import './PromoType.scss';

const types = [
    {
        title: 'DIRECT',
        type: promoType.direct,
        img: <Ico1 />,
    },
    {
        title: 'LANDING PAGE',
        type: promoType.landings,
        img: <Ico2 />,
    },
    {
        title: 'Branding',
        type: promoType.brandings,
        img: <Ico3 />,
    },
    {
        title: 'Banner',
        type: promoType.banners,
        img: <Ico4 />,
    },
    {
        title: 'Video',
        type: promoType.video,
        img: <Ico5 />,
    }
];

function PromoType({ type, setPromo, isWrapped }) {
    const [active, setActive] = useState('');

    useEffect(() => {
        setActive(type);

        if ( !_.isEmpty(isWrapped) ) {
            setPromo({}, 'isWrapped');
        }
        // eslint-disable-next-line
    },[]);

    const handleClick = type => {
        setPromo(type, 'type');
        setActive(type);
    };

    return (
        <div className="row promo-type__wrapper pt-5">
            {types.map((i, id) => (
                <div key={id} className="col mb-5">
                    <div
                        className="text-center promo-type__item"
                        onClick={() => handleClick(i.type)}
                    >
                        <FakeCheckbox isActive={i.type === active}>
                            {i.img}
                            {/*<img src={} className="promo-type__logo mb-4" alt="1"/>*/}
                            <span className="text-small">{i.title}</span>
                        </FakeCheckbox>
                    </div>
                </div>
            ))}
        </div>
    );
}

PromoType.propTypes = {
    type: PropTypes.string.isRequired,
    setPromo: PropTypes.func.isRequired,
    isWrapped: PropTypes.object.isRequired
};

export default PromoType;
