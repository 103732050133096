import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { PaymentTable } from '../../Payment';
import ShortPreloader from '../../ShortPreloader';
import { Input } from '../../Form';
import {ReactComponent as OverviewIco} from '../../../assets/img/inner-menu/stat.svg';
import {ReactComponent as CommissionIco} from '../../../assets/img/inner-menu/owl.svg';
import {ReactComponent as PaymentIco} from '../../../assets/img/inner-menu/pay.svg';
import './ProfileTable.scss';

function ProfileTable({className, data, payInfo, loading, refshare, cparate}) {
    const [key, setKey] = useState('overview');

    return (
        <div className={`${className} profile__table`}>
            <div className="profile__tabs">
                <Tabs activeKey={key} onSelect={k => setKey(k)}>
                    <Tab
                        eventKey="overview"
                        title={<div className="tab-item">
                            <OverviewIco /> Overview
                        </div>}
                    >
                        <div className="d-flex">
                            {!loading ?
                                <PaymentTable data={data}/> :
                                <ShortPreloader/>
                            }
                        </div>
                    </Tab>
                    <Tab
                        eventKey="commissions"
                        title={
                            <div className="tab-item">
                                <CommissionIco /> Commissions
                            </div>
                        }
                    >
                        <div className="text">
                            CPA {cparate} EUR
                        </div>
                        <div className="text">
                            Revshare {refshare}%
                        </div>

                    </Tab>
                    <Tab
                        eventKey="details"
                        title={
                            <div className="tab-item">
                                <PaymentIco /> Payment details
                            </div>
                        }
                    >
                        {!!payInfo ?
                            <Input
                                name="logInfo"
                                defaultValue={payInfo}
                                readOnly
                            /> : null}
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

ProfileTable.propTypes = {
    className: PropTypes.string.isRequired,
    data: PropTypes.array,
    loading: PropTypes.bool,
    payInfo: PropTypes.string,
    cparate: PropTypes.string.isRequired,
    refshare: PropTypes.string.isRequired
};

export default ProfileTable;
