import React, {memo} from 'react';
import UserCongrats from '../Header/InnerHeader/UserCongrats';
import MoneyBalance from '../MoneyBalance';
import './Figure.scss';

const Figure = memo(() => (
    <div className="figure">
        <div className="figure__wrapper">
            <UserCongrats className="figure__brand" />
            <MoneyBalance mode={3} className="figure__balance" />
        </div>
    </div>
));

export default Figure;