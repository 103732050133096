import React from 'react';
import {array, objectOf, string} from 'prop-types';
import {CartesianGrid, ComposedChart, Line, Bar, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend} from 'recharts';
import CustomTooltip from '../CustomTooltip';
import CustomLegend from '../CustomLegend';

const CustomChart = ({ data, filterData = null }) => (
    <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
            data={data}
            margin={{
                top: 0, right: 0, left: -30, bottom: 0,
            }}
        >
            <CartesianGrid
                horizontal
                vertical={false}
                horizontalFill={['#555555', '#444444']}
                fillOpacity={0.2}
                strokeDasharray="2"
                stroke={'#F9C942'}
            />
            <XAxis dataKey="name"/>
            <YAxis
                interval="preserveStartEnd"
                type="number"
                domain={[0, 500]}
                tickCount={6}
            />
            <Tooltip content={
                <CustomTooltip
                    fValue={filterData ? filterData.fValue : null}
                    sValue={filterData ? filterData.sValue : 'NGR'}
                />
            }/>
            <Line
                type="monotone"
                dataKey="line"
                stroke={'#DC3545'}
                activeDot={{r: 8}}
            />
            <Bar dataKey="bar" fill="#F9C942"/>
            {filterData &&
            <Legend
                content={
                    <CustomLegend
                        fValue={filterData.fValue}
                        sValue={filterData.sValue}
                    />
                }
            />
            }
        </ComposedChart>
    </ResponsiveContainer>
);

CustomChart.propTypes = {
    data: array,
    filterData: objectOf(string),
};

export default CustomChart;