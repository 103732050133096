import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Toggler.scss';

const Toggler = ({ clicked, onClick, className }) => (
    <div className={`header-toggle ${className}`}>
        <div
            className={classNames('header-toggle__wrap', { activated: clicked })}
            onClick={onClick}>
            <span />
            <span />
            <span />
            <span />
        </div>
    </div>
);

Toggler.propTypes = {
    clicked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
};

export default Toggler;
