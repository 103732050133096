import React, { useContext }  from 'react';
import PropTypes from "prop-types";
import Pagination from './Pagination';
import TableFilter from './TableFilter';
import { StatsContext } from '../../../context';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';

import 'react-table-hoc-fixed-columns/lib/styles.css'
import ReactTooltip from 'react-tooltip'

import './StatsTable.scss';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function StatsTable ({stats, options, total, hiddenCols}) {
    const { tableOption: isExist } = useContext(StatsContext);

    const columns = [
        {
            Header: 'Date',
            accessor: 'Date',
            width: 100,
            fixed: 'left',
            show: isExist !== 'players' && !hiddenCols.includes('Date'),
            Footer: ``
        },
        {
            Header: 'Player ID',
            accessor: 'Player_ID',
            width: 100,
            fixed: 'left',
            show: isExist === 'players' && !hiddenCols.includes('Player_ID')
        },
        {
            Header: 'Link ID',
            accessor: 'Link_ID',
            width: 100,
            fixed: 'left',
            show: isExist === 'uid' && !hiddenCols.includes('Link_ID'),
        },
        {
            Header: 'Media Name',
            accessor: 'Media_Name',
            width: 100,
            show: ( isExist === 'uid' && !hiddenCols.includes('Media_Name') )
               || ( isExist === 'players' && !hiddenCols.includes('Media_Name') ),
        },
        {
            Header: 'SubId',
            accessor: 'SubId',
            width: 100,
            fixed: isExist === 'subid' ? 'left': false,
            show: ( isExist === 'subid' && !hiddenCols.includes('SubId') )
               || ( isExist === 'players' && !hiddenCols.includes('SubId') ),
        },
        {
            Header: 'Offers',
            accessor: 'Offers',
            width: 100,
            show: isExist === 'uid' && !hiddenCols.includes('Offers')
        },
        {
            Header: 'Brand',
            accessor: 'Brand',
            width: 100,
            show: isExist === 'players' && !hiddenCols.includes('Brand')
        },
        {
            Header: 'Reg Date',
            accessor: 'Reg_Date',
            width: 100,
            show: isExist === 'players' && !hiddenCols.includes('Reg_Date')
        },
        {
            Header: 'IP',
            accessor: 'IP',
            width: 100,
            show: isExist === 'players' && !hiddenCols.includes('IP')
        },
        {
            Header: 'Country',
            accessor: 'Country',
            width: 100,
            show: isExist === 'players' && !hiddenCols.includes('Country')
        },
        {
            Header: props => <span data-tip="all unique visitors">Host</span>,
            accessor: 'Host',
            Footer: `${total.Host}`,
            show: !hiddenCols.includes('Host')
        },
        {
            Header: 'Clicks',
            accessor: 'Clicks',
            Footer: `${total.Clicks}`,
            show: !hiddenCols.includes('Clicks')
        },
        {
            Header: 'Regs',
            accessor: 'Regs',
            Footer: `${total.Regs}`,
            show: !hiddenCols.includes('Regs')
        },
        {
            Header: props => <span data-tip="first time deposit">FTD</span>,
            accessor: 'FTD',
            Footer: `${total.FTD}`,
            show: !hiddenCols.includes('FTD')
        },
        {
            id: 'Conversion',
            Header: props => <span data-tip="Convesion Regs2Deps">Conversion %</span>,
            accessor: props => props.Conversion ? `${props.Conversion} %` : props.Conversion,
            Footer: `${total.Conversion} %`,
            show: !hiddenCols.includes('Conversion')
        },
        {
            Header: props => <span data-tip="active players playing on real money">Players</span>,
            accessor: 'Players',
            Footer: `${total.Players}`,
            show: !hiddenCols.includes('Players')
        },
        {
            Header: props => <span data-tip="total number of deposits">Deposits</span>,
            accessor: 'Deposits',
            Footer: `${total.Deposits}`,
            show: !hiddenCols.includes('Deposits')
        },
        {
            Header: 'Withdraws EUR',
            accessor: 'Withdraws_EUR',
            Footer: `${total.Withdraws_EUR}`,
            show: !hiddenCols.includes('Withdraws_EUR')
        },
        {
            id: 'CP_EUR',
            Header: props => <span data-tip="all bonuses and cashbacks">CP EUR</span>,
            accessor: d => d.CP_EUR.toFixed(2),
            Footer: `${total.CP_EUR.toFixed(2)}`,
            sortMethod: (a, b) => a - b,
            show: !hiddenCols.includes('CP_EUR'),
        },
        {
            id: 'NGR',
            Header: 'NGR',
            accessor: d => d.NGR.toFixed(2),
            Footer: `${total.NGR.toFixed(2)}`,
            sortMethod: (a, b) => a - b,
            show: !hiddenCols.includes('NGR')
        },
        {
            id: 'CPA_commission',
            Header: props => <div style={{ 'lineHeight': '15px' }}>CPA <div>commission</div></div>,
            accessor: d => d.CPA_commission.toFixed(2),
            Footer: `${total.CPA_commission.toFixed(2)} €`,
            show: !hiddenCols.includes('CPA_commission')
        },
        {
            id: 'Revshare_commission',
            Header: props => <div style={{ 'lineHeight': '15px' }}>Revshare <div>commission</div></div>,
            accessor: d => d.Revshare_commission.toFixed(2),
            Footer: `${total.Revshare_commission.toFixed(2)} €`,
            show: !hiddenCols.includes('Revshare_commission')
        }
    ];

    return (
        <>
            <TableFilter
                hidden={hiddenCols}
                options={options}
                data={stats}
            />
            <div className="s-table">
                <ReactTooltip effect="solid" />
                <ReactTableFixedColumns
                    data={stats}
                    columns={columns}
                    minRows={0}
                    pageSize={20}
                    PaginationComponent={Pagination}
                />
            </div>
        </>
    );
}

StatsTable.propTypes = {
    stats: PropTypes.arrayOf(PropTypes.object),
    option: PropTypes.arrayOf(PropTypes.string),
    total: PropTypes.object.isRequired,
};

export default StatsTable;
