import React, {lazy} from 'react';
import history from './router/history';
import { Router, Switch } from 'react-router-dom';
import { OuterLayout, InnerLayout } from './layouts';
import Main from './routes/Main';
import { Testimonials, TestimonialItem } from './routes/Testimonials';
import {News, NewsItem} from './components/News';
import ForgotPassword from './routes/ForgotPassword';
import { PrivateRouter, PublicRouter } from './router';
import Dashboard from './routes/Dashboard';
import Profile from './routes/Profile';
import Promos from './routes/Promos';
import PromosArchive from './routes/PromosArchive';
import AffiliateLink from './routes/AffiliateLink';
import Idle from './components/Idle/Idle';
import Stats from './routes/Stats';
import Payments from './routes/Payments';
import PostbackSettings from './routes/PostbackSettings';
import TrackingList from './routes/TrackingList';
const Policy = lazy(() => import('./routes/Policy'));
const Terms = lazy(() => import('./routes/Terms'));
const Faq = lazy(() => import('./routes/Faq'));

function App() {
  return (
      <Router history={history}>
          <Idle>
              <Switch>
                  <PublicRouter layout={OuterLayout} path='/' exact component={Main} />
                  <PublicRouter layout={OuterLayout} path='/news/:id' component={NewsItem} />
                  <PublicRouter layout={OuterLayout} path='/news' component={News} />
                  <PublicRouter layout={OuterLayout} path='/policy' component={Policy} lazy={true} />
                  <PublicRouter layout={OuterLayout} path='/terms' component={Terms} lazy={true} />
                  <PublicRouter layout={OuterLayout} path='/faq' component={Faq} lazy={true} />
                  <PublicRouter layout={OuterLayout} path='/testimonials/:id' component={TestimonialItem} />
                  <PublicRouter layout={OuterLayout} path='/testimonials' component={Testimonials} />
                  <PublicRouter layout={OuterLayout} path='/forgot-password' component={ForgotPassword} />

                  <PrivateRouter layout={InnerLayout} path='/stats' component={Stats} />
                  <PrivateRouter layout={InnerLayout} path='/promos' component={Promos} />
                  <PrivateRouter layout={InnerLayout} path='/profile' component={Profile} />
                  <PrivateRouter layout={InnerLayout} path='/payments' component={Payments} />
                  <PrivateRouter layout={InnerLayout} path='/dashboard' component={Dashboard} />
                  <PrivateRouter layout={InnerLayout} path='/new-promo' component={TrackingList} />
                  <PrivateRouter layout={InnerLayout} path='/sub-affiliate' component={AffiliateLink} />
                  <PrivateRouter layout={InnerLayout} path='/promos-archive' component={PromosArchive} />
                  <PrivateRouter layout={InnerLayout} path='/postback-settings' component={PostbackSettings} />
              </Switch>
          </Idle>
      </Router>
  );
}

export default App;