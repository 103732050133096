import React from 'react';
import PropTypes from 'prop-types';
import FakeCheckbox from '../../../Form/FakeCheckbox';
import eye from "../../../../assets/img/proview.png";
import './LayoutBoxItem.scss';

const LayoutBoxItem = ({ list, active, onClick }) => {
    return (
        <div
            className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4 layout__item"
            onClick={onClick(list)}
        >
            <div
                className="layout__item-box"
                style={{backgroundImage: `url(${list.preview})`}}
            >
                <FakeCheckbox isActive={active === list.name}>
                    <a
                        href={list.link}
                        target="blank"
                        className="layout__href"
                    >
                        <img
                            className="layout__img-prev"
                            src={eye}
                            alt="prev"
                        />
                    </a>
                </FakeCheckbox>
            </div>
        </div>
    );
};

LayoutBoxItem.propTypes = {
    list: PropTypes.object,
    active: PropTypes.string,
    onClick: PropTypes.func.isRequired
};

export default LayoutBoxItem;
