import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import PromoType from '../PromoType';
import PromoLink from '../PromoLink';
import PromoBrand from '../PromoBrand';
import PromoDelivery from '../PromoDelivery';
import LayoutFilter from '../PromoLayout/LayoutFilter';
import { BannerLayout, BrandingLayout, LandingLayout, VideoLayout } from '../PromoLayout';
import { layoutsAction, deliveryAction, getRefAction } from '../../../actions';
import { promoTypes } from '../../../constants';

function CurrentStep(props) {
    const {
        promo: {
            brand,
            type,
            layout,
        },
        hash,
        stepId,
        setPromo,
        layoutsState,
        layoutsAction,
        deliveryAction,
        isWrapped,
        getRefAction,
        resetPromo,
        referralInfo: {
            lang,
            //type: directType
        },
    } = props;

    const [ refType, setRefType ] = useState(promoTypes.landings);
    const [ directLang, setDirectLang ] = useState('');

    useEffect(() => {
        if ( type === promoTypes.landings || type === promoTypes.direct ) {
            setRefType(type)
        }
    }, [type]);

    switch (stepId) {
        case 1:
            return (
                <PromoBrand
                    brand={brand}
                    setPromo={setPromo}
                />
            );
        case 2:
            return (
                <PromoType
                    type={type}
                    setPromo={setPromo}
                    isWrapped={isWrapped}
                />
            );
        case 3:
            if (type === promoTypes.landings) {
                return (
                    <>
                        <LayoutFilter
                            hash={hash}
                            option={promoTypes.landings}
                            filterAction={layoutsAction}
                        />
                        <LandingLayout
                            data={layoutsState}
                            setPromo={setPromo}
                        />
                    </>
                );
            } else if (type === promoTypes.brandings) {
                return (
                    <>
                        <LayoutFilter
                            option={promoTypes.brandings}
                            filterAction={layoutsAction}
                        />
                        <BrandingLayout
                            data={layoutsState}
                            layout={layout}
                            setPromo={setPromo}
                        />
                    </>
                );
            } else if (type === promoTypes.banners) {
                return (
                    <>
                        <LayoutFilter
                            option={promoTypes.banners}
                            filterAction={layoutsAction}
                            all
                        />
                        <BannerLayout
                            data={layoutsState}
                            layout={layout}
                            setPromo={setPromo}
                        />
                    </>
                );
            } else {
                return (
                    <>
                        <LayoutFilter
                            option={promoTypes.video}
                            filterAction={layoutsAction}
                        />
                        <VideoLayout
                            data={layoutsState}
                            layout={layout}
                            setPromo={setPromo}
                        />
                    </>
                );
            }
        case 4:
            return (
                <>
                    <LayoutFilter
                        option={promoTypes.landings}
                        filterAction={deliveryAction}
                        setRefType={setRefType}
                        setDirectLang={setDirectLang}
                        refType={refType}
                        promoType={type}
                        reff
                    />
                    <PromoDelivery
                        hash={hash}
                        promoType={type}
                        setPromo={setPromo}
                        directLang={directLang}
                        refType={refType}
                    />
                </>
            );
        default:
            return (
                <PromoLink
                    hash={hash}
                    type={type}
                    lang={lang}
                    isWrapped={isWrapped}
                    getRefAction={getRefAction}
                    resetPromo={resetPromo}
                />
            )
    }
}

CurrentStep.propTypes = {
    hash: PropTypes.string.isRequired,
    stepId: PropTypes.number,
    brand: PropTypes.string,
    type: PropTypes.string,
    layout: PropTypes.string,
    setPromo: PropTypes.func.isRequired,
    layoutsState: PropTypes.object.isRequired,
    layoutsAction: PropTypes.func.isRequired,
    deliveryAction: PropTypes.func.isRequired,
    isWrapped: PropTypes.object.isRequired,
    referralInfo: PropTypes.object.isRequired,
    getRefAction: PropTypes.func.isRequired,
    resetPromo: PropTypes.func.isRequired,
};

const mapDispatchToProps = (
    dispatch,
    {
        hash,
        isWrapped: { link },
        referralInfo,
    }) => {
    return {
        layoutsAction: (promoType) => (
            dispatch(layoutsAction({
                hash,
                ...promoType
            }))),
        deliveryAction: (params) => (
            dispatch(deliveryAction({
                hash,
                ...params
            }))),
        getRefAction: () => (
            dispatch(getRefAction({
                hash,
                link,
                ...referralInfo
            }))
        )
    }
};

export default connect(
    ({ layoutsState }) => ({ layoutsState }),
    mapDispatchToProps
)(CurrentStep);
