import React from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';
import './Button.scss';

const Button = ({ onClick, className, children, ...props }) =>  (
    <button
        type="submit"
        className={classNames('button-default', className)}
        onClick={onClick}
        {...props}
    >
        {children}
    </button>
);

Button.defaultProps = {
    className: null,
    onClick: null
};

Button.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func
};

export default Button;