import React from 'react';
import PropTypes from 'prop-types';
import img1 from '../../../assets/img/inner/CS_imagechips3.png';
import img2 from '../../../assets/img/inner/CS_imagechips4.png';
import './TotalBalance.scss';

const TotalBalance = ({ className, total }) => (
    <div className={className}>
        <div className="d-flex inner-balance">
            <div className="col-6 text-center inner-balance__item">
                <img className="inner-balance__label" src={img1} alt="rates"/>
                <div className="text-small inner-balance__data">
                    Regs: <span>{total && total.Regs > 0 ? total.Regs : 0}</span>
                    <div style={{fontSize: '10px', lineHeight: '10px', textAlign: 'center'}}>All time data</div>
                </div>
            </div>
            <div className="col-6 text-center inner-balance__item">
                <img className="inner-balance__label" src={img2} alt="rates"/>
                <div className="text-small inner-balance__data">
                    FTD: <span>{total && total.FTD > 0 ? total.FTD : 0 }</span>
                    <div style={{fontSize: '10px', lineHeight: '10px', textAlign: 'center'}}>All time data</div>
                </div>
            </div>
        </div>
    </div>
);

TotalBalance.propTypes = {
    className: PropTypes.string.isRequired,
    total: PropTypes.shape({
        Regs: PropTypes.number,
        FTD: PropTypes.number
    }),
};

export default TotalBalance;
